.U .column-header{
    font-weight: bold;
    text-transform: uppercase;
    padding: 8px;
    background-color: #f0e886;
    border-bottom: 2px solid #ccc;
    color: #000000;
    text-align: center;
    display: inline-block;
    width: 33%;
    text-align: center;
}
.U .datos span {
    display: inline-block;
    width: 33%;
    text-align: center;
    font-size: large;
}  
.U .editar{
    width: 33%;
    display: flex;
    justify-content: space-around;
}

.edit-button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgb(20, 20, 20);
    border: none;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.164);
    cursor: pointer;
    transition-duration: 0.3s;
    overflow: hidden;
    position: relative;
    text-decoration: none;
  }
  
  .edit-svgIcon {
    width: 17px;
    transition-duration: 0.3s;
  }
  
  .edit-svgIcon path {
    fill: white;
  }
  
  .edit-button:hover {
    width: 120px;
    border-radius: 50px;
    transition-duration: 0.3s;
    background-color: rgb(255, 69, 69);
    align-items: center;
  }
  
  .edit-button:hover .edit-svgIcon {
    width: 20px;
    transition-duration: 0.3s;
    transform: translateY(60%);
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  
  .edit-button::before {
    display: none;
    content: "Edit";
    color: white;
    transition-duration: 0.3s;
    font-size: 2px;
  }
  
  .edit-button:hover::before {
    display: block;
    padding-right: 10px;
    font-size: 13px;
    opacity: 1;
    transform: translateY(0px);
    transition-duration: 0.3s;
  }
  
  .delete-button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgb(20, 20, 20);
    border: none;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.164);
    cursor: pointer;
    transition-duration: 0.3s;
    overflow: hidden;
    position: relative;
  }
  
  .delete-svgIcon {
    width: 15px;
    transition-duration: 0.3s;
  }
  
  .delete-svgIcon path {
    fill: white;
  }
  
  .delete-button:hover {
    width: 90px;
    border-radius: 50px;
    transition-duration: 0.3s;
    background-color: rgb(255, 69, 69);
    align-items: center;
  }
  
  .delete-button:hover .delete-svgIcon {
    width: 20px;
    transition-duration: 0.3s;
    transform: translateY(60%);
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  
  .delete-button::before {
    display: none;
    content: "Delete";
    color: white;
    transition-duration: 0.3s;
    font-size: 2px;
  }
  
  .delete-button:hover::before {
    display: block;
    padding-right: 10px;
    font-size: 13px;
    opacity: 1;
    transform: translateY(0px);
    transition-duration: 0.3s;
  }