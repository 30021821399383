@import "../../Assets/colors/colors.css";
body{
  overflow: hidden;
  background-color: var(--secundary-color-l);
}
.login-seccion{
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-image: url("../../Assets/Images/Baners_jpg/backLogin.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.login-main-box{
  background: white;
  width: 70%;
  height: 80%;
  display: flex;
  border-radius: 10px;
  box-shadow: 7px 13px 37px #000000;
}
.login-main-box .imagen{
  width: 70%;
  content: url("../../Assets/Images/Baners_jpg/fondoLogin.png");
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.login-box{
  width: 35%;
  display: flex;
  flex-direction: column;
  font-size: 20px;
  padding: 2%;
}
.login-box input {
  margin-bottom: 10%;
}
.login-box input[type="text"], .login-box input[type="password"] {
  width: 100%;
  height: 40px;
  background: #ffffff;
  color: rgb(0, 0, 0);
  font-size: 1em;
  border: 1px solid #000000;
  border-radius: 10px;
  margin-bottom: 2vh;
  padding: 2%;
}
.password input[type="text"]{
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.login-box input[type="password"] {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  -webkit-text-security: disc;
}
.login-box input[type="submit"] {
  width: 100%;
  border: none;
  outline: none;
  color: #fff;
  font-size: 1em;
  border-radius: 10px;
  padding: 2%;
  background:black;
}
.login-box img{
  height: 15%;
  content: url("../../Assets/Images/Logos/Logo_2.png");
}
.login-box input[type="submit"]:hover {
  cursor: pointer;
  border: 2px solid transparent;
  border-color: #fff;
}
.login-box label{
  font-weight: bold;
  padding: 2%;
}
.lg{
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-top: 10%;
  margin-bottom: 5%;

  hr{
    transform: rotate(90deg);
    background-color: black;
  }
  span{
    color: black;
    font-weight: bolder;
  }
}

.password{
  display: flex;
}
.password button{
  width: 40px;
  height: 100%;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  z-index: 999;
  margin-inline-start: -1%;
}
.password i{
  font-size: 1.7em;
}
.fyp {
  transition: 0.4s;
  font-size: 15px;
  margin-bottom: 15px;
  color: inherit;
  text-decoration: none;
}

.fyp:visited {
  color: inherit;
}

.fyp:hover {
  color: #000;
  transform: scale(1.1);
}
@media (max-width:700px) {
  .login-main-box .imagen{
    display: none;
  }
  .login-box{
    width: 100%;
  }
}

