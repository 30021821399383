.tableContainer{
    background-color: white;
    padding: 20px 25px;
    border-radius: 10px;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    max-width: 1500px;

    table{
        width: 100%;
    }
    tbody{
        display: flex;
        flex-direction: column;
        gap: 10px;
        max-height: 500px;
        overflow: auto;
    }

    ::-webkit-scrollbar{
        width: 2px;
    }
    
}
.titulo{
    border-bottom: 1px #b9b9b9 solid;
    padding-bottom: 5px;
    display: flex;
    justify-content: space-between;
}
