@import url("../../Assets/colors/colors.css");
.contain-link{
    height: 100%;
    border-radius: 10px;
    background-color: #ffffff;
    padding: 15px;
    display: grid;
    flex-direction: row;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    transition: .4s ease-in-out;
    grid-template-columns: 100%;
    grid-template-rows: repeat(3,1fr);
    grid-template-areas: 
    "titulo"
    "btn"
    "link";
    gap: 10px;
}

.contain-link .sec1-cl{
    display: flex;
    color:var(--font-color-t);
    font-size: 1rem;
    grid-area: titulo;
}

.contain-link .sec1-cl i{
    font-size: 1.5rem;
}

.contain-link .sec2-cl{
    display: flex;
    font-size: 1rem;
    grid-area: btn;
}
.contain-link .sec2-cl button{
    color:var(--font-color-tm);
    padding-inline: 5px;
    font-size: 1rem;
    border-radius: 5px;
    background-color: transparent;
    border: 1px solid gray;
}
.contain-link .sec2-cl button:hover{
    color: black;
    border: 1px solid rgb(0, 0, 0);
    transition: .2s ease-in-out;
}
.contain-link .sec2-cl i{
    font-size: 1rem;
}
.contain-link .sec2-cl p{
    font-size: 1rem;
    color:var(--font-color-t);
    align-self: center;
    margin-inline-start: 20px;
}
.contain-link .sec3-cl {
    display: flex;
    font-size: 1rem;
    grid-area: link;
}
.contain-link .sec3-cl input{
    padding-inline: 5px;
    font-size: 1rem;
    width: 40%;
    border: none;
    border-bottom: 1px solid black;
}

.contain-link .sec3-cl input:focus {
    outline: none;
    box-shadow: none;
}

@media(max-width:600px){
    .contain-link .sec2-cl p{
        display: none;
    }
    .contain-link .sec3-cl input{
        width: 0px;
        height: 0px;
        border: none;
    }
}

@media(max-width:800px){
    .contain-link .sec2-cl p{
    }
    .contain-link .sec3-cl input{
    }
}

@media(max-width:1200px){
    .contain-link .sec3-cl input{
        width: 80%;
    }
    
}