.PagarFacturas {
    display: flex;
    flex-direction: column;
    gap: 40px;

    .titulo-pf {
        height: 75px;
        border-bottom: 1px solid #b9b9b9;
    }
}

.PagarFacturas .contenido-pf {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 50px;

    .titulo {
        font-size: 20px;
        color: #717171;
        font-weight: 600;
    }
}

.transaccion-area-pf {
    background-color: rgb(255, 255, 255);
    display: grid;
    grid-template-columns: 50% 45%;
    justify-content: space-around;
    justify-self: center;
    border-radius: 10px;
    max-width: 1500px;
    padding: 15px;
    margin: 0;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    transition: 0.3s;
}

.contenido-pf .wallets {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 10px;
}

.contenido-pf .inputArea {
    display: flex;
    flex-direction: column;
    gap: 10px;
}


.contenido-pf .walletOpcion {
    width: 100%;
    height: 100px;
    min-width: 200px;
    border-radius: 10px;
    background-color: #f4f4f4;
    padding: 15px;
    display: flex;
    border: 1px solid #c3c3c3;
    flex-direction: column;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    transition: .4s ease-in-out;

    h3 {
        font-size: 1.2rem;
        line-height: 2rem;
        color: rgb(84, 75, 75);
        text-wrap: wrap;
    }

    span {
        font-size: 2rem;
        line-height: 2rem;
        font-weight: 600;
        letter-spacing: -0.025em;
        line-height: 1;
        white-space: wrap;
    }

    div {
        display: flex;
    }

    img {
        line-height: 2rem;
        width: 30px;
        height: 30px;
        justify-self: center;
        align-self: center;
        margin-inline-end: 10px;
    }
}

.contenido-pf .walletOpcion:hover {
    transform: scale(1.02);
    box-shadow: none;
}

.contenido-pf .seleccionado {
    border: 3px solid #010a89;
}

.transaccion-area-pf .notas-pf {
    display: flex;
    flex-direction: column;
    gap: 10px;

    h4 {
        color: #305394;
        font-weight: 600;
    }

    .copyAddrs {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .line {
        background-color: #c4c4c4;
        width: 45%;
        height: 1px;
        border-radius: 5px;
    }

    .qr {
        display: flex;
        flex-direction: column;
        gap: 10px;

        img {
            border: 1px solid #d4d4d4;
            border-radius: 5px;
            width: 300px;
            align-self: center;
        }
    }

    .or {
        display: flex;
        align-items: center;
        justify-content: space-around;
    }

}

@media(max-width:910px) {
    .transaccion-area-pf {
        display: flex;
        flex-direction: column;
        gap: 50px;
        height: fit-content;
    }
}

@media(max-width:520px) {
    .confirmarTransaccion-contenido {
        align-self: baseline;
    }
}


/* .PagarFacturas {
    display: grid;
    grid-template-rows: 75px auto auto;
    grid-template-areas:
        "titulo-re"
        "contenido-re"
        "historial-re";
    gap: 20px;
}

.titulo-re {
    grid-area: titulo-re;
    border-bottom: 1px solid #333;
}

.PagarFacturas .contenido {
    padding: 15px;
    margin: 0;
    height: 550px;
    max-width: 1500px;
    display: grid;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    justify-self: center;
    justify-content: space-around;
    grid-template-columns: 65% 30%;
    border-radius: 10px;
    transition: 0.3s;
}

.PagarFacturas .retirar {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.PagarFacturas .titulo {
    font-size: 20px;
    color: #717171;
    font-weight: 600;
}

.PagarFacturas .wallets {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
    min-height: 180px;
}

.PagarFacturas .walletOpcion {
    width: 100%;
    height: 100px;
    min-width: 200px;
    border-radius: 10px;
    background-color: #f4f4f4;
    padding: 15px;
    display: flex;
    border: 1px solid #c3c3c3;
    flex-direction: column;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    transition: .4s ease-in-out;
}

.PagarFacturas .walletOpcion h3 {
    font-size: 1.2rem;
    line-height: 2rem;
    color: rgb(84, 75, 75);
    text-wrap: wrap;
}

.PagarFacturas .walletOpcion span {
    font-size: 2rem;
    line-height: 2rem;
    font-weight: 600;
    letter-spacing: -0.025em;
    line-height: 1;
    white-space: wrap;
}

.PagarFacturas .walletOpcion div {
    display: flex;
}

.PagarFacturas .walletOpcion:hover {
    transform: scale(1.02);
    box-shadow: none;
}

.PagarFacturas .walletOpcion img {
    line-height: 2rem;
    width: 30px;
    height: 30px;
    justify-self: center;
    align-self: center;
    margin-inline-end: 10px;
}

.PagarFacturas .seleccionado {
    border: 3px solid #010a89;
}

.PagarFacturas .notas {
    background-color: #e3e3e3;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.PagarFacturas .notas p {
    text-align: left;
}

.Retiros .notas span {
    text-decoration: none;
    color: #010a89;
}

.PagarFacturas .notas .link {
    text-decoration: none;
    color: #010a89;
    font-size: 17px;
}

/////////////////HISTORIAL////////

.Retiros .historial {
    border-radius: 10px;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    margin: 0;
    padding: 15px;
    background-color: white;
    width: 100%;
    max-width: 1500px;
    justify-self: center;
    max-height: 400px;
    overflow-y: auto;
}

.Retiros .historial .titulo {
    height: 40px;
    font-size: 16px;
    border-bottom: 1px solid #c3c3c3;
    color: #555454;
}

.Retiros .historial table {
    text-align: center;
    width: 100%;
    table-layout: 0 400px;
    border-spacing: 0 10px;
    border-collapse: separate;
}

.Retiros .historial table tbody {
    border-color: #E5E7EB;
}

.Retiros .historial tr {
    background-color: #f6f6f6;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    margin-bottom: 20px;
    overflow: hidden;  Necesario para los bordes redondeados 
}

.Retiros .historial tr {
    padding: 10px; /* Ajusta el espacio dentro de las celdas 
    border-radius: 12px; /* Borde redondeado 
    background-color: #f6f6f6; /* Color de fondo de la fila 
}

.Retiros .historial span{
    font-weight: 600;
}

.Retiros .acept span{
    font-weight: 600;
    color: green;
}
.Retiros .r span{
    font-weight: 600;
    color: red;
}
.Retiros .historial .e{
    font-weight: 600;
    color: orange;
}

.Retiros .aviso{
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 1px solid #c3c3c3;
}

.Retiros .historial table td {
    padding: 1rem 0.75rem;
    color: #374151;
    border-top: 1px solid #363636;
    border-bottom: 1px solid #363636;
}

.Retiros .historial td:not(:first-child):not(:last-child) {
    border-left: none;
    border-right: none;
}

.Retiros .historial tr td:first-child {
    border-left: 1px solid #363636;
}

.Retiros .historial tr td:last-child {
    border-right: 1px solid #363636;
}

.Retiros .historial tr:first-child td:first-child {
    border-top-left-radius: 10px;
}

.Retiros .historial tr:first-child td:last-child {
    border-top-right-radius: 10px;
}

.Retiros .historial tr:last-child td:first-child {
    border-bottom-left-radius: 10px;
}

.Retiros .historial tr:last-child td:last-child {
    border-bottom-right-radius: 10px;
}

.Retiros .historial tr:hover,
thead:hover {
    background-color: #e4e8f0;
}


.qrPagoFac{
    grid-area: "contenido-re";
    display: flex;
    flex-direction: column;
    padding: 15px 30px;
    justify-self: center;
    height: auto;
    max-width: 1500px;
    width: 100%;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    border: 1px solid #ddd;
    border-radius: 8px;
}

.qrPagoFac .titulo {
    font-size: 20px;
    color: #717171;
    font-weight: 600;
}

.qrPagoFac  .qr-section{
    display: flex;
    justify-content:space-evenly ;
    flex-wrap: wrap;
    gap: 10px;
}

.qrPagoFac .contenidoqrpf{
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1100px;
    gap: 20px;
}


.qrPagoFac .qr-section {
    display: flex;
    margin-top: 20px;
}

.qrPagoFac .qr-section img{
    width: 300px;
    height: 300px;
    border: 1px solid black;
    border-radius: 5px;
}
.qrPagoFac .qrToPay{
    display: flex;
    flex-direction: column;
}


.DatosFactura{
    background-color: #e3e3e3;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.DatosFactura p{
    font-size: 18px;
    font-weight: 500;
}



@media(max-width:910px) {
    .Retiros .contenido {
        display: flex;
        flex-direction: column-reverse;
        gap: 10px;
        height: fit-content;
    }
}

@media(max-width:720px) {
    .Retiros .walletOpcion {
        width: 100%;
        height: 100px;
    }
} */