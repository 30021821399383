@import url("../../Assets/colors/colors.css");
.CardPacks {
    padding: 20px;
    width: 320px;
    height: 400px;
    transition: .4s ease-in-out;
    border-radius: 15px; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border:3px solid transparent;
    position: relative;
}

.CardPacks:hover {
    transform: scale(1.1);
    background-color: white;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}
.imgPack{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -70px;
}
.imgPack .img1{
    width: 200px;
}
.imgPack .img2{
    width: 250px;
    height: 50px;
}
.porcentaje{
    position: absolute;
    bottom: 30px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}
.porcentaje p{
    font-size: 1.5rem;
    text-align: center;
    font-weight: 600;
    margin-bottom: 30px;
}
.porcentaje .boton1{
    width: 70%;
}