.Retiros-ec {
    display: grid;
    width: 100%; 
    grid-template-rows: 60px auto;
}

.Retiros-ec p {
    font-size: 25px;
    font-weight: 600;
}

.content-rec{
    display: grid;
    grid-template-columns: 49% 49%;
    grid-template-rows: 250px;
    justify-content: space-between;
}

.sec1-rec {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.sec1-rec p {
    font-size: 20px;
    font-weight: 600;
}

.textin-rec {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.textin-rec input {
    width: 100%;
    font-size: 15px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 255, 0.27);
    outline: none;
}

.textin-rec input:focus {
    border: 2px solid rgba(0, 0, 255, 0.27);
}

.textin-rec p {
    font-size: 16px;
    font-weight: 200;
}

.sec1-rec button {
    width: 100%;
    padding: 15px;
    background-color: rgba(23, 49, 248, 0.831);
    border: none;
    border-radius: 5px;
    color: white;
    font-weight: 600;
    transition: .5s;
}

.sec1-rec button:hover {
    background-color: rgba(41, 65, 250, 0.831);
    cursor: pointer;
}

.sec2-rec .containTable {
    overflow-x: auto;
}

.sec2-rec table {
    text-align: left;
    width: 100%;
    border-collapse: collapse;
    table-layout: auto;
}

.sec2-rec table thead {
    background-color: #F3F4F6;
    border-bottom: 1px solid rgba(0, 0, 0, 0.265);

}

.sec2-rec table th {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 1rem;
    padding-right: 1rem;
    color: #374151;
    font-weight: 500;
}

.sec2-rec table tbody {
    border-top-width: 1px;
    border-color: #E5E7EB;
}

.sec2-rec table td {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 1rem;
    padding-right: 1rem;
    color: #374151;
    border-bottom: 1px solid rgba(0, 0, 0, 0.265);
}