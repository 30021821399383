@import url("../../../Assets/colors/colors.css");

.seccion-main {
    height: 100%;
    width: 100%;
    background-color: #efefef;
    padding: 0;
    margin: 0;
    display: flex;
    overflow-y: hidden;
}

.dashboard {
    height: 100%;
    width: 100%;
    margin: 0;
    grid-template-columns: repeat(6, 1fr) ;
    grid-template-rows: 75px 200px repeat(3,150px) auto 150px 25rem 30rem 250px;
    display: grid;
    grid-template-areas:
    "sec0 sec0 sec0 sec0 sec0 sec0"
    "sec1 sec1 sec2 sec2 sec3 sec3"
    "sec4 sec4 sec4 sec5 sec5 sec5"
    "sec6 sec6 sec7 sec7 sec8 sec8"
    "sec9 sec9 sec9 sec10 sec10 sec10"
    "sec11 sec11 sec11 sec11 sec11 sec11"
    "sec12 sec12 sec12 sec12 sec12 sec12"
    "sec13 sec13 sec13 sec13 sec13 sec13"
    "sec15 sec15 sec15 sec15 sec15 sec15"
    "sec14 sec14 sec14 sec14 sec14 sec14";
    justify-content: space-between;
    padding: 2%;
    padding-top: 0;
    gap: 15px;
    overflow: hidden;
    margin-bottom: 50px;
}
.item-grid{
    align-content: center;
    width: 100%;
}

.sec0 {
    grid-area: sec0;
    font-family: 'Courier New', Courier, monospace;
    display: flex;
    align-items: center;
}
.sec0 span{
    font-size: 1.5rem;
    margin-inline-start: 10px;
    font-weight: 700;
}
.sec0 i{
    font-size: 1.7rem;
    /*color: var(--color-1);*/
    color: black;
}
.sec1{
    grid-area: sec1;
}
.sec2 {
    grid-area: sec2;
}
.sec3 {
    grid-area:sec3;
}
.sec4 {
    grid-area: sec4;
}
.sec5 {
    grid-area: sec5;
}
.sec6 {
    grid-area:sec6;
}
.sec7 {
    grid-area: sec7;
}
.sec8 {
    grid-area: sec8;
}
.sec9 {
    grid-area: sec9;
}
.sec10{
    grid-area: sec10;
}
.sec11{
    grid-area: sec11;
}
.sec12{
    grid-area: sec12;
}
.sec13{
    grid-area: sec13;
}
.sec13 img{
    width: 100%;
    height: 100%;
    content: url("../../../Assets/Images/Baners_jpg/ISLAS-MALDIVAS-1.webp");
    border-radius: 10px;
    display: flex;
    border-radius: 10px;
}
.sec14{
    grid-area: sec14;
}
.sec15{
    grid-area: sec15;

    img{
        width: 100%;
        height: 100%;
        content: url("../../../Assets/Images/Baners_jpg/ISLAS-MALDIVAS-2.webp");
        border-radius: 10px;
        display: flex;
        border-radius: 10px;
    }
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 5px;
}

::-webkit-scrollbar-track {
    background-color: #eee;
}

@media(max-width:1000px) {
    .dashboard {
        grid-template-columns: 100%;
        grid-template-rows: 75px repeat(10,150px) auto 150px auto auto 400px;
        grid-template-areas:
        "sec0"
        "sec1"
        "sec2"
        "sec3" 
        "sec4"
        "sec5"
        "sec6"
        "sec7" 
        "sec8"
        "sec9"
        "sec10"
        "sec11"
        "sec12"
        "sec13" 
        "sec15" 
        "sec14";
    }

    .sec15 img{
        content: url("../../../Assets/Images/Baners_jpg/ISLAS-MALDIVAS-2\ \(1\).webp");
        
    }
}