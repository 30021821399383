@import "../../Assets/colors/colors.css";

body {
  overflow: hidden;
}

.register-seccion {
  height: 100vh;
  width: 100vw;
  overflow: auto;
  display: flex;
  background-color: #ffffff;
}

.form-register {
  width: 50%;
  height: 100%;
  background: white;
  display: flex;
  flex-direction: column;
  font-size: 20px;
  padding: 4%;
  padding-top: 1%;
  overflow-y: auto;
}

.form-register img {
  width: 50%;
  height: 15%;
  content: url("../../Assets/Images/Logos/Logo_2.png");
}

.form-register h2 {
  color: #2e385c;
  margin-bottom: 2%;
}

.form-register h4 {
  color: #2e385c;
  margin-bottom: 3%;
}

.form-register span {
  font-weight: bold;
  margin-bottom: 1%;
  color: #667197;
}

.form-register input {
  width: 100%;
  background: #ffffff;
  color: #2e385c;
  font-size: 1em;
  border: 1px solid #000000;
  border-radius: 10px;
  margin-bottom: 2%;
  padding: 2%;
}

.form-register label {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 1%;
}

.form-register .botonsE {
  cursor: not-allowed;
  opacity: 0.5
}

.form-register .botons:hover {
  border: 2px solid transparent;
  border-color: #fff;
  background-color: rgb(130, 130, 230);
}

.form-register h5 {
  color: #667197;
  margin-bottom: 1%;
}

/*segundo*/
.msj-seccion {
  width: 50%;
  height: 95%;
  font-size: 1em;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--primary-color);
  border-radius: 20px;
  margin-top: auto;
  margin-bottom: auto;
  margin-inline-end: 2%;
}

.msj-seccion .img1 {
  margin-top: 20%;
  width: 25%;
  content: url("../../Assets/Images/Logos/Logo_1pq.png");
}

.msj-seccion .img2 {
  width: 35%;
  content: url("../../Assets/Images/Logos/Logo_1l.png");
}

.msj-seccion span {
  font-weight: bold;
  font-size: 2em;
  text-align: center;
}

.register-seccion .show {
  height: 50px;
}


@media(max-width:600px) {
  .msj-seccion {
    display: none;
  }

  .form-register {
    width: 100%;
  }
}

@media(max-width:400px) {
  .msj-seccion {
    display: none;
  }

  .form-register {
    width: 100%;
  }
}

@media(max-width:1000px) {
  .form-register img {
    width: 80%;
    align-self: center;
  }

  .form-register input {
    margin-bottom: 5%;
  }
}

@media(max-width:1200px) {
  .msj-seccion span {
    padding: 5%;
  }

}