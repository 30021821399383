.mainAdmin {
    width: 100%;
    height: 100vh;
    background-color: aliceblue;
    overflow: hidden;
    padding: 0;
    padding-bottom: 20px;
    margin: 0;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows:100px 100px 220px auto;
    grid-template-areas: 
    "header"
    "nav"
    "link"
    "contenido";
    overflow-y: auto;
    overflow-x: hidden;
}
header{
    grid-template-columns: auto 100px;
    grid-template-rows: 100px;
    display: grid;
    grid-template-areas: 
    "logo cs";
    border-bottom: 1px solid rgb(130, 130, 130);
}
header .logo{
    display: flex;
    grid-area: logo;
}
header .logo img{
    content:url("../../../Assets/Images/Logos/Logo_2.png") ;
}
header .cs{
    display: flex;
    justify-content: flex-end;
    align-items:center ;
    grid-area: cs;
    padding: 30px;
}

.ops{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    border-bottom: 1px solid rgb(130, 130, 130);
    grid-area: nav;
}
.ops button{
    width: 10%;
    border-radius: 10px;
    padding: 2px;
    background-color: rgb(237, 165, 9);
    color: rgb(0, 0, 0);
    font-weight: bold;
    font-size: 100%;
}
.ops button:hover{
    border: 2px solid white;
    color: white;
    background-color: rgb(238, 173, 32);
}
.linkC{
    grid-area: link;
    padding: 2%;
}
.contenido-A{
    grid-area: contenido;
}
.tabla{
    width: 96%;
    height: 800px;
    background-color: #ffffff;
    padding: 2%;
    margin: 2%;
    border: 2px solid rgb(143, 143, 143);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    border-radius: 20px;
    overflow-y: auto;
}
::-webkit-scrollbar {
    width: 10px;
}
::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 5px; 
}
::-webkit-scrollbar-track {
    background-color: #eee;
}
.tabla .column-header{
    font-weight: bold;
    text-transform: uppercase;
    padding: 8px;
    background-color: #f0e886;
    border-bottom: 2px solid #ccc;
    color: #000000;
    text-align: center;
    display: inline-block;
    width: 20%;
    text-align: center;
}
.tabla .datos{
    background-color: #ffffff;
    overflow-y: auto;
    max-height: 550px;
}
.tabla .datos li{
    display: flex;
    justify-content: space-between;
    padding: 8px;
    border-bottom: 1px solid #ccc;   
}
.tabla .datos span {
    display: inline-block;
    width: 20%;
    text-align: center;
}  
.tabla .aprobar{
    width: 20%;
    display: flex;
    justify-content: space-around;
}
.tabla .aprobar .check{
    width: 15%;
    background-color: transparent;
    border-color: transparent;
    color: green;
    font-weight: bold;
    font-size: 200%;
}
.tabla .aprobar .trash{
    width: 15%;
    background-color: transparent;
    border-color: transparent;
    font-weight: bold;
    font-size: 200%;
    color: rgb(255, 0, 0);
    text-align: center;
}
.tabla li,ul{
    list-style-type: none;
}
.encabezado{
    padding: 0.5%;
    background-color:#6f6f6f00;
    display: flex;
    align-items: center;
}
.encabezado h2{
    margin-inline-end: 5%;
}
.encabezado .buscador{
    margin-inline-end: 50%;
    display: flex;
}
.encabezado .buscador input{
    padding: 3%;
    height: 100%;
    background: #ffffff;
    color: rgb(0, 0, 0);
    border: 1px solid #1f53c5;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}
.encabezado .buscador button{
    padding: 2%;
    border: 1px solid #1f53c5;
    color:#1f53c5 ;
    font-size: 100%;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}
.encabezado .buscador i{
    font-size: 10px;
}
.encabezado .refresh{
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    background-color: transparent;
    border: none;
    border-radius: 50%;
}
.refresh:hover {
    background: rgba(170, 170, 170, 0.224);
    transition: 0.5s;
}
  
@media(max-width:850px){
    .encabezado{
        align-items: start;
        flex-direction: column;
    }
}
@media(max-width:1900px){
    .tabla .datos{
        max-height: 500px;
    }
}
@media(max-width:700px){
    .tabla .column-header{
        font-size: 50%;
    }
    .tabla .datos{
        font-size: 50%;
        max-height: 400px;
    }

}
@media (max-width:1400px) {
    .ops button{
        width: 30%;
        font-size: 20px;
    }
}
@media (max-width:500px) {
    .ops{
        height: 50px;
    }
    .ops button{
        width: 25%;
        font-size: 12px;
        padding: 0;
        width: 70px;
    }
    header .logo img {
        width: 200px;
    }
}
