.TSV {
    background-color: white;
    border-radius: 5px;
    padding: 10px;
    height: auto;
}

.TSV .contenido {
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 0;
    padding-top: 15px;
    background-color: white;
    gap: 10px;
}

.TSV .contenido button {
    border-radius: 5px;
    width: 150px;
    height: 50px;
    border: 1px solid #333;
    background-color: white;
    font-size: 18px;
    background-color: #0f1ab4;
    color: white;
}

.TSV .contenido button:hover {
    background-color: #363fbf;
    color: white;
}

.TSV .resaltado {
    height: auto;
    grid-area: s31-up;
    background-color: rgba(135, 207, 235, 0.443);
    padding: 5px;
    border-radius: 5px;
    display: block;
}

.TSV .resaltado p {
    font-weight: 600;
    color: rgb(23, 23, 137);
}

.TSV .inputData {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.TSV .inputData input {
    width: 300px;
    border-radius: 5px;
    padding-inline: 5px;
    height: 35px;
    border: 1px solid black;
}

.TSV .inputData input:focus {
    border-color: #0011ff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    outline: none;
}

.TSV .inputData p {
    font-size: 18px;
}

.TSV .inputData span {
    font-weight: 600;
}

.TSV .completado {
    height: 100px;
    display: flex;
    font-size: 25px;
    font-weight: 600;
    color: #555454;
    align-items: center;
}

.TSV .completado i {
    font-size: 27px;
    color: green;
    margin-inline-start: 10px;
}

.TSV header {
    margin: 0;
    padding: 0;
    display: flex;

    h1 {
        width: 100%;
        height: 40px;
        width: 100%;
        font-size: 22px;
    }
}



@media(max-width:370px) {

}