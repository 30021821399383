.Tools{
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 50px 550px;
    grid-template-areas: 
    "sec0-to sec0-to"
    "sec1-to sec2-to";
    padding: 10px 30px;
}

.sec0-to{
    grid-area: sec0-to;
    border-bottom: 2px solid #a9a9a9;
}
.sec1-to{
    grid-area: sec1-to;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 35px;
    font-weight: 600;
    color: #333;
    padding: 10px;
    margin-top: 20px;
}
.Tools p{
    margin-bottom: 20px;
}
.Tools iframe{
    width: 100%;
    height: 450px;
}
.sec2-to{
    grid-area: sec2-to;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 35px;
    font-weight: 600;
    color: #333;
    padding: 10px;
    margin-top: 20px;
}

@media(max-width:1000px){
    .Tools{
        grid-template-columns: 100%;
        grid-template-rows: 50px 550px 550px;
        grid-template-areas: 
        "sec0-to"
        "sec1-to"
        "sec2-to";
    }
}