.Cart-ec{
    display: flex;
}
.cart-modal-ec {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 100;
    display: flex;
    top: 0;
    left: 0;
    z-index: 100;
    justify-content: center;
    align-items: center;
}

.overlay-ec {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.484);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
}

.contenido-cart {
    width: 350px;
    height: 500px;
    background-color: whitesmoke;
    z-index: 101;
    flex-direction: column;
    display: flex;
    padding: 5px 5px;
    color: #2d2d2d;
    font-size: 16px;
    gap: 10px;
    border-radius: 10px;
    border: 1px solid #969696;
}

.sec1-ecc,
.sec3-ecc,
.sec4-ecc,
.sec5-ecc,
.sec6-ecc {
    padding: 0 15px;
    font-weight: 500;
    font-size: 18px;
    display: flex;
}

.sec3-ecc,
.sec4-ecc,
.sec5-ecc {
    justify-content: space-between;
}

.sec0-ecc {
    display: flex;
    justify-content: flex-end;
}

.sec0-ecc button {
    padding: 0;
    background-color: transparent;
    color: black;
    border: none;
    font-size: 25px;
    border-radius: 5px;
}

.Cart-ec .cartbtn{
    padding: 10px;
    background-color: white;
    color: black;
    border: none;
    font-size: 25px;
    border-radius: 5px;
}

.sec1-ecc {
    font-size: 25px;
    font-weight: 600;
}
.sec4-ecc{
    border-bottom: 2px solid #00000025;
    padding-bottom: 10px;
}
.sec7-ecc{
    padding: 15px;
}
.sec7-ecc button {
    width: 100%;
    padding: 5px;
    background-color: rgba(23, 49, 248, 0.831);
    border: none;
    border-radius: 5px;
    color: white;
    font-weight: normal;
    transition: 5s;
    font-size: 18px;
}

.sec7-ecc button:hover {
    background-color: rgba(41, 65, 250, 0.831);
    cursor: pointer;
}

.sec6-ecc {
    margin: 20px;
    display: flex;
    justify-content: center;
}

.payment-select {
    width: 500px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

.payment-select:focus {
    outline: none;
    border-color: #007bff;
}
.sec2-ecc{
    padding-inline: 15px ;
    height: 300px;
    border-bottom: 2px solid #00000025;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}
.sec2-ecc .s21-ecc{
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    align-items: center;
}
.sec2-ecc .item-ecc{
    display: flex;
    flex-direction: column;
}
.sec2-ecc div p{
    font-weight: normal;
    font-size: 18px;
}
.sec2-ecc div span{
    font-weight: normal;
    font-size: 16px;
    color: #929292;
}
.sec2-ecc button{
    background-color: transparent;
    padding: 0;
    border: none;
    font-size: 25px;
    border-radius: 5px;
}