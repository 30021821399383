.headerSec{
    width: 100%;
    height: 100%;
    background-color: #070d3b;
    display: grid;
    grid-template-columns: 75px auto 100px 150px;
    grid-template-rows: auto;
    grid-area: 
        "sec1 sec2 sec3 sec4";
}

.sech1{
    display: flex;
    justify-content: center;
    align-items: center;
}
.sech2{

}
.sech3{

}
.sech4{
    display: flex;
    justify-content: center;
    align-items: center;
}

.btnMenu {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(255, 255, 255);
    background-color: transparent;
    border: none;
}
.btnMenu i{
    font-size: 30px;
}























