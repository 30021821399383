.animated-div {
    position: fixed;
    top: 5%;
    left: -100%;
    width: 25%;
    height: 50px;
    background-color: red;
    color: white;
    font-size: 15px;
    text-align: start;
    align-content: center;
    transition: left 1s;
    z-index: 9999;
    border-color: white;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
  }
  
  .animated-div.visible {
    left: 1%; 
  }
  .animated-div .texto-red{
    position: absolute;
    top: 25%;
    width: 100%;
    background: red;
    align-content: center;
    font-weight: bold;
    padding-inline-start: 2%;
    border-radius: 5px;
  }

  .animated-div .texto-green{
    width: 100%;
    background: rgb(16, 150, 16);
    align-content: center;
    font-weight: bold;
    padding-inline-start: 2%;
    border-radius: 5px;
  }

  .animated-div .texto{
    align-content: center;
    font-weight: bold;
    padding-inline-start: 2%;
    border-radius: 5px;
  }

  .progress-container {
    border: none;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 10px;
    background-color: red;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  
  .progress-bar {
    height: 100%;
    background-color: #ffffff;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  


  @media(max-width:600px){
    .animated-div{
      width: 50%;
      font-size: 10px;
    }
    .animated-div button{
      font-size: 20px;
    }
  }

    @media(max-width:1000px){
      .animated-div{
        width: 50%;
        font-size: 14px;
      }
      .animated-div button{
        font-size: 25px;
      }
    
  }