.eaec{
    display: flex;
    flex-direction: column;
}
.eaec::-webkit-scrollbar{
    width: 2px;
}
.sec-eaec{
    width: 320px;
    height: 300px;
    padding: 10px;
    border-radius: 10px;
    border: 2px solid rgb(101, 166, 222);
    background-color: aliceblue;
    overflow-y: auto;
}

.sec0-eaec{
    font-size: 22px;
    font-weight: 600;
    color: #7a7a7a;
}
.sec1-eaec{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
}

.sec2-eaec{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.eaec .titulo{
    font-size: 20px;
    font-weight: 600;
    color: rgb(105, 105, 223);
}
.sec2-eaec select {
    width: 100%;
    height: 40px;
    padding: 5px;
    border-radius: 5px;
    font-size: 16px;
}
.sec2-eaec button{
    align-self: center;
    width: 150px;
    font-size: 16px;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #9f9f9f;
    background-color: rgb(255, 255, 255);
    transition: .4s;
}
.sec2-eaec button:hover{
    background-color: #e9e8e8;
}
.sec3-eaec{
    display: flex;
    flex-direction: column;
}
.sec3-eaec::-webkit-scrollbar {
    width: 1px;
    background-color: #f5f5f5; /* Color del fondo del scrollbar */
  }
.sec3-eaec .productos{
    border-bottom: 1px solid #333;
    margin-bottom: 10px;
}

.sec3-eaec .productos p{
    font-size: 18px;
    font-weight: 500;
    color: black;
}
.sec3-eaec .productos span{
    font-size: 16px;
    color: rgb(161, 161, 161);
}
.sec3-eaec .productos .productos-rv{
    display: flex;
    justify-content: space-between;
}

.sec4-eaec p{
    margin-top: 10px;
    font-size: 18px;
}
.sec4-eaec span{
    margin-top: 10px;
    font-size: 19px;
    color: #668af8;
}
.sec5-eaec{
}

.sec5-eaec p{
    margin-top: 10px;
    font-size: 18px;
}
.sec5-eaec span{
    margin-top: 10px;
    font-size: 19px;
    color: #668af8;
}

@media(min-width:1700px){
    .sec-eaec{
        width: 320px;
        height: 500px;
    }
}

@media(max-width:730px){
    .sec1-eaec{
        justify-content: center;
    }
}