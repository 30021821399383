@import url("../../Assets/colors/colors.css");

.layout {
    width: 100vw;
    height: 100vh;
    padding: 0;
    margin: 0;
    overflow-x: hidden;
}

.containDash {
    grid-template-columns: auto 1fr;
    grid-template-rows: 100px auto 50px;
    display: grid;
    grid-template-areas:
        "sec1 sec2"
        "sec1 sec3"
        "sec1 sec4";
        background-color: #070d3b;
}

.cabeza {
    height: 100%;
    background-color: #070d3b;
    border-bottom: 1px groove rgb(210, 210, 210);
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.142);
    grid-area: sec2;
}

.sidebar {
    display: flex;
    grid-area: sec1;
    background: #070d3b;
}

.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    grid-area: sec3;
    background-color: #efefef;
}

.contenido {
    height: 100%;
    width: 100%;
    background-color: #efefef;
    margin: 0;
    display: grid;
    padding: 2%;
    padding-top: 0;
    overflow: hidden;
    margin-bottom: 70px;
}

.footer {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-area: sec4;
    font-size: 15px;
    font-weight: 600;
    color: #606060;
    background-color: #efefef;

}

.contenido .titulos {
    font-family: 'Courier New', Courier, monospace;
    display: flex;
    align-items: center;
}

.contenido .titulos span {
    font-size: 1.5rem;
    margin-inline-start: 10px;
    font-weight: 700;
}

.contenido .titulos i {
    font-size: 1.7rem;
    color: var(--color-1);
}

.contenido .textoG {
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 2rem;
}

.contenido .textoM {
    display: block;
    margin-bottom: 10px;
    font-weight: 500;
    color: #333;
}

.contenido .textoM2 {
    display: block;
    margin-bottom: 10px;
    font-weight: 600;
    color: #333;
}

.contenido .textoM3 {
    display: block;
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 20px;
    color: #333;
}


.boton1 {
    /* Variables */
    --button_radius: 0.75em;
    --button_color: #e8e8e8;
    --button_outline_color: #000000;
    font-size: 17px;
    font-weight: bold;
    border: none;
    border-radius: var(--button_radius);
    background: var(--button_outline_color);
}

.boton2 {
    height: 50px;
    background: url("../../Assets/Images/Baners_jpg/Gold.png");
    color: white;
    font-size: 1.2rem;
    border-radius: 5px;
    transition: 0.3s ease-in-out;
}

.boton2:hover {
    scale: 1.1;
}

.boton3 {
    height: 50px;
    font-size: 1.2rem;
    border-radius: 5px;
    transition: 0.3s ease-in-out;
}

.boton3:hover {
    scale: 1.1;
}

.button_top {
    display: block;
    box-sizing: border-box;
    border: 2px solid var(--button_outline_color);
    border-radius: var(--button_radius);
    padding: 0.75em 1.5em;
    background: var(--button_color);
    color: var(--button_outline_color);
    transform: translateY(-0.2em);
    transition: transform 0.1s ease;
}

.boton1:hover .button_top {
    /* Pull the button upwards when hovered */
    transform: translateY(-0.33em);
}

.boton1:active .button_top {
    /* Push the button downwards when pressed */
    transform: translateY(0);
}

.boton4 {
    height: 50px;
    width: 300px;
    background-color: white;
    border-radius: 5px;
    border: 1px solid #333;
    transition: .3s;
    color: #333;
    font-size: 20px;
}

.boton4:hover {
    scale: 1.1;
    border: 2px solid #333;
    background-image: url("../../Assets/Images/Baners_jpg/Gold.png");
    font-weight: 500;
}

::-webkit-scrollbar {
    width: 3px;
    /* Ancho de la scrollbar vertical */
    height: 3px;
    /* Altura de la scrollbar horizontal */
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
    /* Color del track */
}

::-webkit-scrollbar-thumb {
    background: #888;
    /* Color del thumb */
    border-radius: 4px;
    /* Redondear el thumb */
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
    /* Color del thumb cuando se pasa el cursor sobre él */
}


@media (max-width: 600px) {
    .containDash {
        width: 100%;
        margin: 0;
        padding: 0;
        grid-template-columns: auto 1fr;
        grid-template-rows: 100px auto 50px;
        display: grid;
        grid-template-areas:
            "sec1 sec2"
            "sec1 sec3"
            "sec1 sec4";
    }

    .sidebar {
        display: flex;
        grid-area: sec1;
        width: 0;
        background-color: #070d3b;
    }
}