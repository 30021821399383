.aec {
    display: grid;
    grid-template-rows: 70px;
}

.sec0-aec {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sec0-aec select {
    width: 150px;
    padding: 5px;
    border-radius: 5px;
    font-size: 16px;
}

.sec0-aec button {
    padding: 7px;
    font-size: 25px;
    border-radius: 50px;
    background-color: transparent;
    border: none;
}

.sec0-aec button:hover {
    background-color: rgba(220, 220, 220, 0.579);
}

.sec1-aec {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-between;
    align-content: flex-start;
}

.sec1-aec .ordenes-rv {
    width: 400px;
    height: 300px;
    background-color: white;
    border: none;
    margin: 0;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.372);
}

.sec1-aec .ordenes-rv .s11-aec {
    background-color: aliceblue;
    height: 70px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
}

.sec1-aec .ordenes-rv .s11-aec p {
    font-size: 17px;
    color: rgb(49, 49, 223);
    font-weight: 600;
}

.sec1-aec .ordenes-rv .s11-aec span {
    font-size: 18px;
    color: rgb(49, 49, 223);
    font-weight: 500;
}
.sec1-aec .ordenes-rv .s11-aec button{
    width: 100%;
    height: 35px;
    border-radius: 5px;
    border: 1px solid rgba(98, 98, 98, 0.197);
    font-size: 16px;
    transition: .4s;
}
.sec1-aec .ordenes-rv .s11-aec button:hover{
    background-color: aliceblue;
    cursor: pointer;
}
.sec1-aec .ordenes-rv .s12-aec {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px 15px;
    gap: 30px;
}
.sec1-aec .ordenes-rv .s12-aec div{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.sec1-aec .ordenes-rv .s12-aec p {
    font-size: 18px;
    color: rgb(113, 113, 113);
    font-size: 20px;
}

.sec1-aec .ordenes-rv .s12-aec span {
    font-size: 18px;
    color: rgb(92, 92, 92);
    font-weight: 600;
}

.sec1-aec .ordenes-rv .s12-aec {
    height: 160px;
}

@media(max-width:905px) {
    .sec1-aec {
        justify-content: center;
    }
}

@media(max-width:460px) {
    .sec1-aec .ordenes-rv {
        width: 270px;
    }

    .sec0-aec {
        flex-wrap: wrap;
    }
}