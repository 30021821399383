@import "../../../Assets/colors/colors.css";

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}

.siderbar-body{
    height: 100vh;
    width: 100%;
}

body{
    height:100vh ;
}

/*-----------------Menu*/
.menu{
    position: fixed;
    width: 50px;
    height: 50px;
    font-size: 30px;
    display: none;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    background-color: var(--color-boton);
    color: var(--color-boton-texto);
    right: 15px;
    top: 15px;
    z-index: 100;
}
/*----------------Barra Lateral*/
.barra-lateral{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 250px;
    height: 100vh;
    overflow: hidden;
    padding: 20px 15px;
    background-color: #070d3b;
    transition: width 0.5s ease,background-color 0.3s ease,left 0.5s ease;
    z-index: 50;
}
.mini-barra-lateral{
    width: 80px;
}
.mini-barra-lateral .navegacion h4{
    display: none;
}
.barra-lateral span{
    width: 100px;
    white-space: nowrap;
    font-size: 15px;
    text-align: left;
    padding-left: 7px;
    opacity: 1;
    transition: opacity 0.5s ease,width 0.5s ease;
}
.barra-lateral span.oculto{
    opacity: 0;
    width: 0;
}
.barra-lateral h4.oculto{
    opacity: 0;
    width: 0;
    display: none;
}
/*--------------> Menu Navegación*/
.barra-lateral .navegacion{
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 10px;
}
.barra-lateral .navegacion li{  
    list-style: none;
    display: flex;
    margin-bottom: 5px;
}
.barra-lateral .navegacion a,.opcionBoton2{
    width: 100%;
    height: 45px;
    display: flex;
    align-items: center;
    text-decoration: none;
    border-radius: 10px;
    color:white;
}
.barra-lateral .navegacion a:hover,.opcionBoton2:hover{
    background-color: var(--color-menu-hover);
    color: var(--color-menu-hover-texto);
    i{
        background-color: var(--color-menu-hover);
        color: var(--color-menu-hover-texto);
    }
}
.barra-lateral .navegacion i:hover,.opcionBoton2 i:hover{
    background-color: var(--color-menu-hover);
    color: var(--color-menu-hover-texto);
}
.barra-lateral .navegacion a i,.opcionBoton2 i{
    min-width: 50px;
    font-size: 30px;
    padding-left: 5px;
    color: white;
    border-radius: 10px;
}
.barra-lateral h4{
    color: gray;
    display: block;
}


.headNav{
    display: flex;
    width: 80px;
    height: 100px;
    padding: 0;
    margin-inline: -15px;
    margin-top: -20px;
    justify-content: center;
    align-items: center;
}

.headNavMax{
    display: flex;
    width: 250px;
    height: 100px;
    padding: 0;
    margin-inline: -15px;
    margin-top: -20px;
    margin-bottom: -20px;
    justify-content: center;
}

.logoNav {
    width: 60px;
    height: 60px;
    content: url("../../../Assets/Images/Logos/Logo_1pq.png");
}
.logoNavMax {
    width: 250px;
    height: 80px;
    content: url("../../../Assets/Images/Logos/Logo_1.png");
}

/*------------> Botón*/
.barra-lateral .boton{
    width: 50px;
    height: 45px;
    margin-bottom: 20px;
    margin-inline-start: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 10px;
    background-color: var(--color-boton);
    color: var(--color-boton-texto);
}
.barra-lateral .boton i{
    width: 24px;
    height: 24px;
    min-width: 50px;
    font-size: 25px;
}


.desplegable{
    width: 100px;
    white-space: nowrap;
    font-size: 15px;
    text-align: left;
    padding-left: 7px;
    opacity: 1;
    transition: opacity 0.5s ease,width 0.5s ease;
}

.barra-lateral .navegacion::-webkit-scrollbar{
    width: 5px;
}
.barra-lateral .navegacion::-webkit-scrollbar-thumb{
    background-color: var(--color-scroll);
    border-radius: 5px;
}
.barra-lateral .navegacion::-webkit-scrollbar-thumb:hover{
    background-color: var(--color-scroll-hover);
}

.btn_logout{
    background-color: white;

}

/*------------------> Responsive*/
@media (max-height: 660px){
    .barra-lateral .nombre-pagina{
        margin-bottom: 5px;
    }
    .barra-lateral .modo-oscuro{
        margin-bottom: 3px;
    }
}
@media (max-width: 600px){
    .barra-lateral{
        left: -250px;
    }
    .max-barra-lateral{
        left: 0;
    }
    .menu{
        display: flex;
    }
    .barra-lateral .boton{
        display: flex;
    }
    .menu i:nth-child(2){
        display: none;
    }
    main{
        margin-left: 0;
    }
    main.min-main{
        margin-left: 0;
    }
    
}