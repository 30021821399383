.Packs {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 75px repeat(2, 500px);
    grid-template-areas:
        "sec0-pa sec0-pa sec0-pa"
        "sec1-pa sec2-pa sec3-pa"
        "sec4-pa sec5-pa sec6-pa";
    row-gap: 45px;
    justify-content: center;
    align-items: center; 
}

.centrado {
    display: flex;
    justify-content: center;
    align-items: center;
}

.sec0-pa {
    grid-area: sec0-pa;
    border-bottom: 1px solid #333;
}

.sec1-pa {
    grid-area: sec1-pa;
}

.sec2-pa {
    grid-area: sec2-pa;
}

.sec3-pa {
    grid-area: sec3-pa;
}

.sec4-pa {
    grid-area: sec4-pa;
}

.sec5-pa {
    grid-area: sec5-pa;
}

.sec6-pa {
    grid-area: sec6-pa;
}

@media(max-width:1250px) {
    .Packs {
        grid-template-columns: repeat(2, 50%);
        grid-template-rows: 75px repeat(3, 500px);
        grid-template-areas:
            "sec0-pa sec0-pa"
            "sec1-pa sec2-pa"
            "sec3-pa sec4-pa"
            "sec5-pa sec6-pa";
    }
}

@media(max-width:750px) {
    .Packs {
        grid-template-columns: 100%;
        grid-template-rows: 75px repeat(5, 500px);
        grid-template-areas:
            "sec0-pa"
            "sec1-pa"
            "sec2-pa"
            "sec3-pa"
            "sec4-pa"
            "sec5-pa"
            "sec6-pa";
    }
}