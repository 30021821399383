.seccionRed {
    padding: 1.5rem;
    background-color: #ecf2fe;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    height: auto;
    overflow-x: hidden;
    overflow-y: hidden;
}

.seccionRed .divTitulo {
    display: flex;
    margin-bottom: 1.5rem;
    justify-content: space-between;
    align-items: center;
}

.seccionRed .divTitulo h2 {
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 700;
}

.seccionRed .redComponent {
    padding: 1.5rem;
    background-color: hsl(235, 77%, 22%);
    border-radius: 0.5rem;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    height: 60rem;
    overflow-x: auto;
    overflow-y: auto;
}

.tree {
	width: auto;
	height: auto;
	text-align: center;
    flex-wrap: nowrap;
}
 .tree ul {
	display: flex;
	justify-content:space-evenly;
	padding-top: 20px;
	position: relative;
	transition: .5s;
}
.tree li {
	display: table-cell;
	text-align: center;
	list-style-type: none;
	position: relative;
	padding: 10px;
	transition: .5s;
}
.tree li::before, .tree li::after {
	content: '';
	position: absolute;
	top: 0;
	right: 50%;
	border-top: 1px solid #ccc;
	width: 51%;
	height: 10px;
}
.tree li::after {
	right: auto;
	left: 50%;
	border-left: 1px solid #ccc;
}
.tree li:only-child::after, .tree li:only-child::before {
	display: none;
}
.tree li:only-child {
	padding-top: 0;
}
.tree li:first-child::before, .tree li:last-child::after {
	border: 0 none;
}
.tree li:last-child::before {
	border-right: 1px solid #ccc;
	border-radius: 0 5px 0 0;
	-webkit-border-radius: 0 5px 0 0;
	-moz-border-radius: 0 5px 0 0;
}
.tree li:first-child::after {
	border-radius: 5px 0 0 0;
	-webkit-border-radius: 5px 0 0 0;
	-moz-border-radius: 5px 0 0 0;
}
.tree ul ul::before {
	content: '';
	position: absolute;
	top: 0;
	left: 50%;
	border-left: 1px solid #ccc;
	width: 0;
	height: 20px;
} 
.tree .final {
	display: none;
} 

.tree li a {
	border: 1px solid #ccc;
	padding: 10px;
	display: inline-grid;
	border-radius: 5px;
	text-decoration-line: none;
	border-radius: 5px;
	transition: .5s;
}

.tree li a img {
	width: 50px;
	height: 50px;
	margin-bottom: 20px;
	border-radius: 100px;
	margin: auto;
}

.tree li a span {
	border: 1px solid #ccc;
	border-radius: 5px;
	color: #666;
	padding: 8px;
	font-size: 12px;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-weight: 500;
}

.tree li a:hover, .tree li a:hover i, .tree li a:hover span, .tree li a:hover+ul li a {
	background: #c8e4f8;
	color: #000;
	border: 1px solid #94a0b4;
}
.tree li a:hover+ul li::after, .tree li a:hover+ul li::before, .tree li a:hover+ul::before, .tree li a:hover+ul ul::before {
	border-color: #94a0b4;
} 


.spinner {
    width: 46px;
    height: 46px;
    display: grid;
    animation: spinner-plncf9 3s infinite;
  }
  
  .spinner::before,
  .spinner::after {
    content: "";
    grid-area: 1/1;
    border: 9px solid;
    border-radius: 50%;
    border-color: #474bff #474bff #0000 #0000;
    mix-blend-mode: darken;
    animation: spinner-plncf9 1s infinite linear;
  }
  
  .spinner::after {
    border-color: #0000 #0000 #dbdcef #dbdcef;
    animation-direction: reverse;
  }
  
  @keyframes spinner-plncf9 {
    100% {
      transform: rotate(1turn);
    }
  }
  
