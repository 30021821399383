@keyframes desplazar {
  0% {
    transform: translateX(100%);
    /* Posición inicial: desplazado hacia arriba */
  }

  100% {
    transform: translateX(0);
    /* Posición final: no desplazado */
  }
}

.alert {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  width: 320px;
  padding: 12px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  border-radius: 8px;
  box-shadow: 0px 0px 5px -3px #111;
  position: fixed;
  z-index: 102;
  right: 40px;
  top: 120px;
  animation: desplazar 1s ease-in-out;
}

.success {
  background: #EDFBD8;
  border: 1px solid #84D65A;
  display: flex;
  align-items: center;

  .icon {
    height: auto;
    display: flex;
    align-items: center;

    path {
      fill: #84D65A;
    }
  }

  .title {
    width: 100%;
    justify-content: space-between;
    display: flex;
    align-items: center;
    text-align: center;
    font-weight: 500;
    font-size: 15px;
    color: #2B641E;
    align-items: center;
    padding-inline-start: 5px;
  }

  .close {
    display: flex;
    align-items: center;

    path {
      fill: #2B641E;
    }
  }
}


.error {
  background: #facbcb;
  border: 1px solid #d65a5a;
  display: flex;
  align-items: center;

  .icon {
    height: auto;
    display: flex;
    align-items: center;

    path {
      fill: #d65a5a;
    }

  }


  .title {
    width: 100%;
    justify-content: space-between;
    display: flex;
    align-items: center;
    text-align: center;
    font-weight: 500;
    font-size: 15px;
    color: #641e1e;
    align-items: center;
    padding-inline-start: 5px;
  }

  .close {
    display: flex;
    align-items: center;

    path {
      fill: #641e1e;
    }
  }
}

@media(max-width:350px) {
  .alert {
    width: 300px;
    right: 10px;
    top: 120px;
  }
}