:root {
    --primary-color: rgb(20, 16, 43);
    --primary-color-w: #ffffff;
    --primary-color-l: rgb(16, 4, 88);
    --secundary-color-l: rgb(226, 226, 226);

    --color-main:rgb(175, 190, 255);
    --color-1:rgb(131, 131, 231);
    --color-2:rgb(18, 18, 181);
    --color-3:rgba(45, 45, 237, 0.504);
    --font-color-t:rgb(84, 75, 75);
    --font-color-tm:rgb(34, 34, 34);


    --color-texto:rgb(0,0,0);
    --color-texto-menu:rgb(208, 208, 210);

    --color-menu-hover:rgb(238,238,238);
    --color-menu-hover-texto:rgb(0,0,0);

    --color-boton:rgb(0,0,0);
    --color-boton-texto:rgb(255,255,255);

    --color-linea:rgb(180,180,180);

    --color-switch-base :rgb(201,202,206);
    --color-switch-circulo:rgb(241,241,241);

    --color-scroll:rgb(192,192,192);
    --color-scroll-hover:rgb(134,134,134);

    --color-texto-normal:#2e385c;
    --color-texto-span: #667197;
    --color-hover:rgb(130, 130, 230) ;
  }