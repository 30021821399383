.IntCFor {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 102;
}

.content-ICF {
    background-color: white;
    z-index: 101;
    width: 100%;
    max-width: 700px;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    border: 2px solid rgb(11, 11, 110);
    overflow-y: auto;
    max-height: 600px;
}

.content-ICF .header {
    padding: 5px;
    display: flex;
    flex-direction: column;
}

.content-ICF .header p {
    font-size: 20px;
    font-weight: 600;
}

.content-ICF .header button {
    width: 20px;
    margin-inline-start: auto;
}

.content-ICF .inputs {
    background: linear-gradient(to right, rgb(57, 99, 238), rgb(168, 185, 244));
    padding: 10px 5px;
    margin-bottom: 10px;
}

.content-ICF .inputs p {
    font-size: 18px;
    font-weight: 600;
    color: white;
}

.content-ICF .inputs input {
    height: 40px;
    width: 100%;
    font-size: 20px;
    border-radius: 5px;
    color: #616161;
    padding: 0 5px;
    margin-bottom: 15px;
}

.content-ICF .inputs select {
    height: 40px;
    width: 100%;
    font-size: 20px;
    border-radius: 5px;
    color: #616161;
    padding: 0 5px;
}

.content-ICF .resultados {
    display: flex;
    flex-direction: column;
}

.content-ICF .resultados .resultado {
    max-height: 400px;
}

.content-ICF .resultados .resultado table {
    text-align: center;
    width: 100%;
    border-collapse: collapse;
    table-layout: auto;
}

.content-ICF .resultados .resultado table thead {
    border-bottom: 1px solid rgba(0, 0, 0, 0.265);
}

.content-ICF .resultados .resultado table thead th {
    color: #697c9b;
}

.content-ICF .resultados .resultado table th {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 1rem;
    padding-right: 1rem;
    color: #374151;
    font-weight: 600;
}

.content-ICF .resultados .resultado table tbody {
    border-top-width: 1px;
    border-color: #E5E7EB;
}

.content-ICF .resultados .resultado table td {
    padding: 1rem 0.75rem;
    color: #374151;
    border-bottom: 1px solid rgba(0, 0, 0, 0.265);
}

.content-ICF .resultados .resultado tr:hover,
thead:hover {
    background-color: #e4e8f0;
}

.content-ICF .resultado::-webkit-scrollbar {
    width: 5px;
    /* Ancho de la scrollbar */
    height: 5px;
    /* Altura de la scrollbar */
}

.content-ICF .resultadosFinal {
    padding: 10px 5px;

}

.content-ICF .resultados .resultadosFinal table {
    text-align: center;
    width: 100%;
    border-collapse: collapse;
    table-layout: auto;
}

.content-ICF .resultados .resultadosFinal table thead th {
    color: #697c9b;
}

.content-ICF .resultados .resultadosFinal table th {
    padding-top: 0.75rem;
    padding-left: 1rem;
    padding-right: 1rem;
    color: #374151;
}

.content-ICF .resultados .resultadosFinal table tbody {
    border-top-width: 1px;
    border-color: #E5E7EB;
}

.content-ICF .resultados .resultadosFinal table td {
    padding: 0 0.75rem;
    color: #374151;
    font-size: 25px;
    font-weight: 600;
}