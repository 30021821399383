.userDetail {
    padding: 1rem;
    display: grid;
    align-items: center;
    background-color: white;
    border-radius: 10px;
    box-shadow: rgba(10, 37, 64, 0.35) 0px -2px 6px 0px;
    height: 100%;
    grid-template-columns: 140px 1fr repeat(2,1fr) ;
    grid-template-rows: 1fr 2fr;
    grid-template-areas:
        "sec1 sec1 sec1 sec1"
        "sec2 sec3 sec4 sec5";
}

.userDetail .item-grid {
    align-content: center;
    width: 100%;
}

.userDetail .sec1 {
    grid-area: sec1;
}

.userDetail .sec1 p {
    color: rgb(108, 106, 106);
    font-size: 1rem;
    line-height: 2rem;
}

.userDetail .sec1 span {
    font-size: 1.5rem;
    line-height: 1.25rem;
    font-weight: 700;
    /*color: var(--color-2);*/
    color: black;
}

.userDetail .sec2 {
    grid-area: sec2;

}

.userDetail .sec3 {
    grid-area: sec3;
}

.userDetail .sec3 p {
    display: flex;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #6B7280;
}

.userDetail .sec3 span {
    font-size: 1.2rem;
    font-weight: bold;
}

.userDetail .sec3 img {
    margin-inline-start: 5px;
    width: 20px;
    height: 20px;
    content: url("../../Assets/Images/Logos//usdt.png");
}

.userDetail .sec4 {
    grid-area: sec4;
}

.userDetail .sec5 {
    grid-area: sec5;
}

.userDetail .rangoImg {
    width: 125px;
    height: 125px;
    margin-bottom: 1%;
    border: 3px solid rgb(207, 137, 17);
    border-radius: 50%;
    background-color: #070d3b;
}

.userDetail .p1 {
    font-size: 25px;
    margin-bottom: 3%;
}

.userDetail .datosUser {
    width: 75%;
    height: 20%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-around;
}

.userDetail .p2 {
    font-size: 20px;
}

.tablaRed {
    margin-top: 50px;
    width: 80%;
    display: flex;
    justify-content: space-around;
}

.datosRedes {
    width: 150px;
    padding: 10px;
    text-align: center;
    background-color: aliceblue;
    border-radius: 10px;
    box-shadow: rgba(10, 37, 64, 0.35) 0px -2px 6px 0px;
}

.titulo {
    font-size: 20px;
    margin-bottom: 5px;
}

.datoRed {
    font-size: 20px;
}

@media (max-width:900px){
    .userDetail {
        grid-template-columns: 140px 1fr repeat(1,1fr) ;
        grid-template-rows: 1fr 2fr;
        grid-template-areas:
            "sec1 sec1 sec4"
            "sec2 sec3 sec5";
    }
}
@media (max-width:600px){
    .userDetail {
        grid-template-columns: 140px 1fr ;
        grid-template-rows: 1fr 2fr 1fr 1fr;
        grid-template-areas:
            "sec1 sec1"
            "sec2 sec3"
            "sec4 sec5";
    }
}