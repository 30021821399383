.Ordenes-ec{
    width: 100%;
    max-height: 290px;
    overflow-y: auto;
    overflow-x: auto;
}

.sec0-oec {
    overflow-x: auto;
}

.sec0-oec table {
    text-align: center;
    width: 100%;
    border-collapse: collapse;
    table-layout: auto;
}

.sec0-oec table thead {
    border-bottom: 1px solid rgba(0, 0, 0, 0.265);
}
.sec0-oec table thead th{
    color: #697c9b;
}

.sec0-oec table th {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 1rem;
    padding-right: 1rem;
    color: #374151;
    font-weight: 600;
}

.sec0-oec table tbody {
    border-top-width: 1px;
    border-color: #E5E7EB;
}

.sec0-oec table td {
    padding: 1rem 0.75rem;
    color: #374151;
    border-bottom: 1px solid rgba(0, 0, 0, 0.265);
}

.sec0-oec tr:hover,thead:hover{
    background-color: #e4e8f0;
}

.sec0-oec button{
    border: 1px solid rgb(207, 207, 207);
    background-color: white;
    padding: 5px 10px;
    color: #374151;
    border-radius: 5px;
    transition: .3s;
}
.sec0-oec button:hover{
    transform: scale(1.1);
}
