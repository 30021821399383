@import url("../../Assets/colors/colors.css");

.compQR-main{
    display: flex;

}
.compQR {
    position: fixed;
    background-color: transparent;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.none {
    display: none;
}

.modal-content {
    position: fixed;
    width: 600px;
    display: grid;
    grid-template-columns: 60% 50%;
    grid-template-rows: 100%;
    grid-template-areas:
        "sec1-qr sec2-qr";
    gap: 0;
    z-index: 102;
}

.sec1-qr {
    padding: 15px;
    border-radius: 0;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    background: #f1f1f1;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 30px 300px 90px 90px 25px 100px;
    grid-template-areas:
        "s1-qr"
        "s2-qr"
        "s3-qr"
        "s4-qr"
        "s11-qr"
        "s5-qr";
    grid-area: sec1-qr;
}

.modal-content p {
    font-size: 1.1rem;
    color: #333;
    font-weight: 500;
    margin-bottom: 10px;
}

.s1-qr {
    grid-area: s1-qr;
}

.s2-qr {
    grid-area: s2-qr;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.s2-qr img {
    width: 300px;
    border: 1px solid black;
    border-radius: 10px;
}

.modal-content .s3-qr {
    grid-area: s3-qr;
    display: flex;
    flex-direction: column;
}

.modal-content .s3-qr .walletInput {
    width: 100%;
    display: flex;
}

.modal-content .s3-qr .walletInput input {
    width: 100%;
    font-size: 1rem;
    border-radius: 5px;
    padding: 10px 0px 10px 10px;
    border: 0.5px solid #ccc;
    font-weight: 600;
}

.modal-content .s3-qr .walletInput input:focus {
    border-color: #0011ff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    outline: none;
}

.modal-content .s3-qr .walletInput button {
    width: 10%;
    background-color: ghostwhite;
    border-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-inline-start: -7PX;
    padding: 2px;
    color: black;
    border: 0.5px solid #9b9b9b;
    transition: .3s;
}

.s4-qr {
    grid-area: s4-qr;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.s4-qr input {
    width: 100%;
    font-size: 1rem;
    border-radius: 5px;
    padding: 10px 0px 10px 10px;
    border: 0.5px solid #ccc;
    font-weight: 600;
}

.s4-qr input:focus {
    border-color: #0011ff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    outline: none;
}

.s5-qr {
    display: grid;
    grid-template-columns: repeat(2, 45%);
    grid-template-rows: auto;
    grid-area: "finish close";
    gap: 15px;
    grid-area: s5-qr;
    justify-content: center;
    align-items: center;
}

.s5-qr .close {
    grid-area: close;
}

.s5-qr .finish {
    grid-area: finish;
}

.s11-qr {
    grid-area: s11-qr;

}

.s11-qr p {
    color: rgb(201, 83, 83);
}

.sec2-qr {
    border: 1px solid rgb(208, 208, 208);
    padding: 15px;
    border-radius: 0;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    grid-area: sec2-qr;
    background: #f1f1f1;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 30px 70px repeat(3, 120px);
    grid-template-areas:
        "s6-qr"
        "s7-qr"
        "s8-qr"
        "s9-qr"
        "s10-qr";
}

.s6-qr {
    grid-area: s6-qr;
}

.s7-qr {
    grid-area: s7-qr;
    display: flex;
}

.s8-qr {
    grid-area: s8-qr;
    display: flex;
    border-top: 2px solid #60606054;
    padding-top: 15px;
}

.s9-qr {
    grid-area: s9-qr;
    display: flex;
    border-top: 2px solid #60606022;
    padding-top: 15px;
}

.s10-qr {
    grid-area: s10-qr;
    display: flex;
    border-top: 2px solid #60606022;
    padding-top: 15px;
    margin-top: 10px;
}

.imgSec2-qr {
    width: 35px;
    height: 35px;
    margin-inline-end: 25px;
}

.overlay {
    background: rgba(49, 49, 49, 0.8);
}

.modal,
.overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
}

.modal-content::-webkit-scrollbar {
    width: 8px;
}

.modal-content::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
}

.modal-content::-webkit-scrollbar-track {
    background-color: #000000;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

@media(max-width:1300px) {
    .modal-content {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        grid-template-columns: 100%;
        grid-template-rows: auto 90%;
        grid-template-areas:
            "sec1-qr"
            "sec2-qr";
        overflow: auto;
        padding-bottom: -15px;
    }
}

@media(max-width:600px) {
    .sec1-qr {}
}