.IComp{ 
    display:flex ;
    flex-direction: column;
    width: 100%;
    background-color: white;
    padding: 10px;
    border-radius: 5px;
    gap: 20px;
}
.IComp header{
    height: 30px;
    border: none;   
    width: 100%;
    font-size: 22px;
    font-weight: 600;
    color: #383737;
}

.IComp .datos{
    display: flex;
    flex-wrap: wrap;
    padding-top: 10px;
    align-items: center;
    justify-content: space-around;
    gap: 15px;
} 
.IComp .datos .datoDiv{
    gap: 10px;
    display: flex;
    flex-direction: column;
}

.IComp .datos .datoDiv .dato{
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 18px;
    font-weight: 600;
    gap: 5px;
}

.IComp .datos .datoDiv .dato input{
    width: 300px;
    border-radius: 5px;
    padding-inline:5px;
    height: 35px;
    border: 1px solid black;
}

.IComp .datos .datoDiv .dato input:focus {
    border-color: #0011ff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    outline: none;
}

.IComp .datos .botones {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 300px;
    gap: 15px;
}

.IComp .datos .botones button{
    border-radius: 5px;
    width: 150px;
    height: 50px;
    border: 1px solid #333;
    background-color: white;
    font-size: 18px;
}
.IComp .datos .botones button i{
    margin-inline-end: 5px;
}

.IComp .datos .botones .azul{
    background-color: #0f1ab4;
    color: white;
}

.IComp .datos .state{
    display: flex;
    flex-direction: column;
    height: max-content;
    width: 300px;
}
.IComp .datos .state p{
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 10px;
}
.IComp .datos .state span{
    font-size: 16px;
}
.IComp .datos .state .green i{
    font-size: 10px;
    color: rgb(22, 175, 22);
}
.IComp .datos .state .red i{
    font-size: 10px;
    color: rgb(208, 38, 38);
}
.IComp .modal1{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 102;
}

.IComp .modal1 .modal1-content{
    background-color: white;
    z-index: 101;
    width: 100%;
    max-width: 700px;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    border: 2px solid rgb(11, 11, 110);
    overflow-y: auto;
    max-height: 600px;
    padding: 15px;
    padding-top: 5px;
}

.IComp .modal1 .modal1-content .header{
    padding: 5px;
    display: flex;
    flex-direction: column;
}

.IComp .modal1 .modal1-content .header button{
    width: 30px;
    margin-inline-start: auto;
    transition: 0.4s;
    background-color: transparent;
    border-radius: 5px;
    border: none;
    font-size: 25px;
}
.IComp .modal1 .modal1-content .header button:hover{
    background-color: #e9e9e9;
}
.IComp .modal1 .modal1-content .body {
    display: flex;
    flex-direction: column;
    gap: 7px;
}
.IComp .modal1 .modal1-content .body p {
    font-size: 19px;
    font-weight: 600;
    color: rgb(83, 83, 83);
}
.IComp .modal1 .modal1-content .body .inputs {
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.IComp .modal1 .modal1-content .body .inputs span {
    font-size: 18px;
    color: rgb(83, 83, 83);
}

.IComp .modal1 .modal1-content .body .inputs select {
    height: 40px;
    width: 100%;
    font-size: 20px;
    border-radius: 5px;
    color: #616161;
    padding: 0 5px;
}

.IComp .modal1 .modal1-content .botones{
    padding: 10px;
    display: flex;
    justify-content: space-around;
    padding-top: 15px;
}

@media(max-width:1100px){
    .IComp .datos .state{
        display: flex;
        flex-direction: column;
        width: 100px;
    }
}

@media(max-width:500px){
    .IComp .datos{
        justify-content: flex-start;
    } 
}