.overlay-av {
    background: rgba(49, 49, 49, 0.8);
}
.Aviso, .overlay-av {
    z-index: 101;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
}

.avisoContent{
    z-index: 101;
    position: fixed;
    width: 300px;
    height: 96%;
    background-color: aliceblue;
    display: grid;
    grid-template-rows: 120px 70px 120px 70px;
    grid-template-areas: 
    "sec0-av"
    "sec1-av"
    "sec2-av"
    "sec3-av";
    border-radius: 5px;
    padding: 10px;
}

.sec0-ev{
    grid-area: sec0-av;
    display: flex;
    justify-content: center;
}
.sec0-ev img{
    width: 130px;
}
.sec1-ev{
    grid-area: sec1-av;
    display: flex;
    justify-content: center;
}
.sec1-ev p{
    font-size: 20px;
    font-weight: 600;
    text-align: center;
}
.sec2-ev{
    grid-area: sec2-av;
    display: flex;
    justify-content: center;
}
.sec2-ev p{
    font-size: 17px;
    text-align: center;
}
.sec3-ev{
    grid-area: sec3-av;
    display: flex;
    justify-content: center;
}
.sec3-ev button{
    width: 250px;
    height: 50px;
    font-size: 20px;
    font-weight: 600;
    border-radius: 5px;
    background-color: white;
    cursor: pointer;
    transition-duration: 0.4s;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.sec3-ev button:hover{
    transform: scale(1.1);
    background-image: url("../../Assets/Images/Baners_jpg/Gold.png");
}

.avisoContent2{
    background-color: aliceblue;
    width: 300px;
    height: 96%;
    z-index: 101;
    border-radius: 5px;
    padding: 10px;
    display: grid;
    grid-template-rows: 35px 310px 25px 90px 60px 60px;
    gap: 5px;
    overflow: auto;
}
.avisoContent2 p{
    color:#696969 ;
    font-weight: 600;
    font-size: 18px;
}
.sec4-ev{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #7d7d7d;
    font-size: 25px;
    font-weight: 600;
}
.sec5-ev{
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.sec5-ev img{
    width: 270px;    
    height: 270px;   
    align-self: center;
    border: 1px solid rgba(0, 0, 0, 0.365);
    border-radius: 10px ;
}
.sec6-ev{
    display: flex;
    align-items: center;
    justify-content: center;
}
.sec6-ev p{
    width: 30px;
    text-align: center;
}
.sec6-ev span{
    width: 40px;
    height: 2px;
    background-color: #696969;
}
.sec7-ev{
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.avisoContent2 input{
    padding: 5px;
    color: #696969;
    border-radius: 5px;
    border: 1px solid black;
    font-size: 15px;
}
.avisoContent2 button{
    width: 100px;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.204);
    background-color: #f0f0f0;
    color: #696969;
    transition: .5s;
}
.avisoContent2 button:hover{
    color: black;
    border: 1px solid rgba(0, 0, 0, 0.745);
}
.sec8-ev{
    display: flex;
    gap: 5px;
    flex-direction: column;
}

.sec9-ev{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
}
.sec9-ev button{
    width: 100%;
}
.sec9-ev button:hover{
    background-image: url("../../Assets/Images/Baners_jpg/Gold.png");
}

@media(min-width:500px){
    .avisoContent2{
        height: auto;
    }
}