.CardProduct{
    display: grid;
    background-color: rgb(244, 243, 243);
    width: 300px;
    height: 400px;
    border: 1px solid #c2c2c2;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    transition: .3s;
}
.CardProduct:hover{
    transform: scale(1.1);
}
.CardProduct2{
    display: grid;
    background-color: rgb(244, 243, 243);
    width: 300px;
    height: 400px;
    border: 1px solid #c2c2c2;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    transition: .3s;
}
.sec0-pec {
    display: flex;
    justify-content: center;
}
.sec0-pec img{
    width: 200px;
    height: 300px;
}
.sec1-pec{
    font-size: 20px;
    color:#333;
    font-weight: 600;
    padding-inline: 10px;
}
.sec2-pec{
    font-size: 16px;
    color:#727272;
    padding-inline: 10px;

}

.sec3-pec{
    display: flex;
    justify-content: space-between;
    padding: 10px;
    align-items: center;
}

.sec3-pec button{
    padding: 7px 25px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #3333334c;
    color: rgb(100, 100, 100);
    font-weight: 600;
}
.sec3-pec button:hover{
    background-color: rgba(23, 49, 248, 0.831);
    color: white;
}

.sec3-pec p{
    font-weight: 600;
    color: rgb(26, 26, 168);
}

.detalles{
    width: 100%;
    height: 100vh;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.406);
    top: 0; 
    left: 0;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
}

.content-ec{
    width: 600px;
    height: 500px;
    position: fixed;
    background-color: #ffffff;
    z-index: 101;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-template-rows: 30px auto;
    grid-template-areas: 
    "sec5-pec sec4-pec" 
    "sec5-pec sec6-pec";
    padding: 10px;
    border: 1px solid #707070;
    border-radius: 10px;
}

.sec4-pec{
    grid-area: sec4-pec;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.sec4-pec button{
    font-size: 25px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: none;
    border-radius: 5px;
}

.sec4-pec button:hover{
    background-color: #c2c2c253;
}

.sec5-pec{
    grid-area: sec5-pec;
    display: flex;
}
.sec5-pec img{
    width: 100%;
    height: 100%;
}

.sec6-pec{
    grid-area: sec6-pec;
    display: flex;
    flex-direction: column;
}

.beneficios {
    list-style-type: none;
    padding-left: 0;
    margin-top: 5px;
}

.beneficios li {
    position: relative;
    padding-left: 30px;
    font-size: 18px; 
    color: #5f5f5f;
    font-size: 17px;
}

.beneficios li::before {
    content: "\2713";
    position: absolute;
    left: 0; 
    color: rgb(0, 21, 128); 
    font-size: 20px;
    font-weight: bold; 
    font-family: Arial, sans-serif; 
}

.sec6-pec p{
    color: #5f5f5f;
    font-size: 16px;
}
.sec6-pec .p1-pec{
    color: #4e4e4e;
    font-size: 28px;
    font-weight: 600;
}
.sec6-pec .p2-pec{
    color: #4e4e4e;
    font-size: 20px;
    font-weight: 600;
    margin-top: 20px;
}
.sec6-pec .p3-pec{
    color: #4e4e4e;
    font-size: 16px;
    font-weight: 600;
    margin-top: 20px;
}
.sec6-pec input {
    width: 100px;
    font-size: 15px;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 255, 0.27);
    outline: none;
    margin-top: 5px;
}

.sec6-pec input:focus {
    border: 2px solid rgba(0, 0, 255, 0.27);
}
.sec6-pec button {
    width: 100%;
    padding: 10px;
    background-color: rgba(23, 49, 248, 0.831);
    border: none;
    border-radius: 4px;
    color: white;
    font-weight: 600;
    transition: .5s;
    margin-top: 50px;
}

.sec6-pec button:hover {
    background-color: rgba(41, 65, 250, 0.831);
    cursor: pointer;
}

.content-ec::-webkit-scrollbar {
    width: 2px; /* Ancho de la scrollbar */
  }

@media(max-width:620px){
    .content-ec{
        width: 90%;
        height: 80%;
        grid-template-columns: 1fr;
        grid-template-rows: 30px auto;
        grid-template-areas: 
        "sec4-pec" 
        "sec5-pec"
        "sec6-pec";
        overflow-y: auto;

    }
    .sec5-pec{
        display: flex;
        justify-content: center;
    }
    .sec5-pec img{
        width: 90%;
        height: 80%;
    }
}
@media(max-width:400px){
    .content-ec{
        height: 90%;
        width: 300px;
        grid-template-columns: 1fr;
        grid-template-rows: 30px auto;
        grid-template-areas: 
        "sec4-pec" 
        "sec5-pec"
        "sec6-pec";
        overflow-y: auto;
    }
    .sec5-pec img{
        width: 100%;
        height: 100%;
    }
}