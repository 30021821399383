

body {
    background-size: cover;
    overflow: hidden;
}

@font-face {
    font-family: 'League Gothic';
    src: url('./Assets/fonts/LeagueGothic-Regular.ttf') format('truetype'),
}

@font-face {
    font-family: 'League Gothic Condensed';
    src: url('./Assets/fonts/LeagueGothic-Condensed.ttf') format('truetype'),
}

@font-face {
    font-family: 'League Gothic Italic';
    src: url('./Assets/fonts/LeagueGothic-Italic.ttf') format('truetype');
}