.Retiros {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.titulo-re {
    grid-area: titulo-re;
    border-bottom: 1px solid #333;
}

.Retiros .contenido {
    padding: 15px;
    margin: 0;
    height: 550px;
    max-width: 1500px;
    display: grid;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    justify-self: center;
    justify-content: space-around;
    grid-template-columns: 65% 30%;
    border-radius: 10px;
    transition: 0.3s;
}

.Retiros .retirar {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.retirar .titulo {
    font-size: 20px;
    color: #717171;
    font-weight: 600;
}

.Retiros .wallets {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
    min-height: 180px;
}

.Retiros .walletOpcion {
    width: 48%;
    height: 180px;
    min-width: 200px;
    border-radius: 10px;
    background-color: #f4f4f4;
    padding: 15px;
    display: flex;
    border: 1px solid #c3c3c3;
    flex-direction: column;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    transition: .4s ease-in-out;
}

.Retiros .walletOpcion h3 {
    font-size: 1.2rem;
    line-height: 2rem;
    color: rgb(84, 75, 75);
    text-wrap: wrap;
}

.Retiros .walletOpcion span {
    font-size: 2rem;
    line-height: 2rem;
    font-weight: 600;
    letter-spacing: -0.025em;
    line-height: 1;
    white-space: wrap;
}

.Retiros .walletOpcion div {
    display: flex;
}

.Retiros .walletOpcion:hover {
    transform: scale(1.02);
    box-shadow: none;
}

.Retiros .walletOpcion img {
    line-height: 2rem;
    width: 30px;
    height: 30px;
    justify-self: center;
    align-self: center;
    margin-inline-end: 10px;
}

.Retiros .seleccionado {
    border: 3px solid #010a89;
}

.Retiros .notas {
    background-color: #e3e3e3;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.Retiros .notas p {
    text-align: left;
}

.Retiros .notas span {
    text-decoration: none;
    color: #010a89;
}

.Retiros .notas .link {
    text-decoration: none;
    color: #010a89;
    font-size: 17px;
}

/*/////////////////HISTORIAL////////*/

.Retiros .historial {
    border-radius: 10px;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    margin: 0;
    padding: 15px;
    background-color: white;
    width: 100%;
    max-width: 1500px;
    justify-self: center;
    max-height: 400px;
    overflow-y: auto;
}

.Retiros .historial .titulo {
    height: 40px;
    font-size: 16px;
    border-bottom: 1px solid #c3c3c3;
    color: #555454;
}

.Retiros .historial table {
    text-align: center;
    width: 100%;
    table-layout: 0 400px;
    border-spacing: 0 10px;
    border-collapse: separate;
}

.Retiros .historial table tbody {
    border-color: #E5E7EB;
}

.Retiros .historial tr {
    background-color: #f6f6f6;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    margin-bottom: 20px;
    overflow: hidden; /* Necesario para los bordes redondeados */
}

.Retiros .historial tr {
    padding: 10px; /* Ajusta el espacio dentro de las celdas */
    border-radius: 12px; /* Borde redondeado */
    background-color: #f6f6f6; /* Color de fondo de la fila */
}

.Retiros .historial span{
    font-weight: 600;
}

.Retiros .acept span{
    font-weight: 600;
    color: green;
}
.Retiros .r span{
    font-weight: 600;
    color: red;
}
.Retiros .historial .e{
    font-weight: 600;
    color: orange;
}

.Retiros .aviso{
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 1px solid #c3c3c3;
}

.Retiros .historial table td {
    padding: 1rem 0.75rem;
    color: #374151;
    border-top: 1px solid #363636;
    border-bottom: 1px solid #363636;
}

/* Eliminar bordes laterales de las celdas que no están en los extremos */
.Retiros .historial td:not(:first-child):not(:last-child) {
    border-left: none;
    border-right: none;
}

/* Mantener bordes en las primeras y últimas celdas de cada fila */
.Retiros .historial tr td:first-child {
    border-left: 1px solid #363636;
}

.Retiros .historial tr td:last-child {
    border-right: 1px solid #363636;
}

.Retiros .historial tr:first-child td:first-child {
    border-top-left-radius: 10px;
}

.Retiros .historial tr:first-child td:last-child {
    border-top-right-radius: 10px;
}

.Retiros .historial tr:last-child td:first-child {
    border-bottom-left-radius: 10px;
}

.Retiros .historial tr:last-child td:last-child {
    border-bottom-right-radius: 10px;
}

.Retiros .historial tr:hover,
thead:hover {
    background-color: #e4e8f0;
}



@media(max-width:910px) {
    .Retiros .contenido {
        display: flex;
        flex-direction: column-reverse;
        gap: 10px;
        height: fit-content;
    }
}

@media(max-width:720px) {
    .Retiros .walletOpcion {
        width: 100%;
        height: 100px;
    }
}


/*.contenido-re{
    padding: 15px;
    width: 1500px;
    height: 550px;
    justify-self: center;
    grid-area: contenido-re;
    display: grid;
    grid-template-columns: repeat(2,35%) calc(30% - 15px);
    grid-template-rows: 30px 200px 50px 50px 50px;
    grid-template-areas: 
    "s0-re s0-re notas-re"
    "s1-re s2-re notas-re"
    "s3-re s3-re notas-re"
    "s4-re s4-re notas-re"
    "s5-re s5-re notas-re";
    background-color: rgb(255, 255, 255);
    margin-top: 20px;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
}

/* .se0-re{
    grid-area: s0-re;
}
.se0-re p{
    font-size: 20px;
    color: #717171;
    font-weight: 600;
}
.se1-re{
    grid-area: s1-re;
}
.se2-re{
    grid-area: s2-re;
}
.se3-re{
    grid-area: s3-re;
}
.se4-re{
    grid-area: s4-re;
}
.se5-re{
    display: flex;
    justify-content: center;
    grid-area: s5-re;
}
.notas-re{
    background-color: #e3e3e3;
    grid-area: notas-re;
    border-radius: 5px;
    display: grid;
    padding: 10px;
}

.notas-re p{
    text-align: left;
}
.notas-re span{
    text-decoration: none;
    color: #010a89;
}
.notas-re .link{
    text-decoration: none;
    color: #010a89; 
    font-size: 17px; 
}



.contain-data{
    border: 1px solid #717171;
    transition: 250ms;
}
.contain-data:hover{
    border: 1px solid #717171;
}

.historial-re{
    height: 100%;
    margin-top: 20px;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    background-color: white;
    height: 700px;
    width: 1000px;
    grid-area: historial-re;
    justify-self: center;
    display: grid;
    grid-template-rows: 50px auto;
    grid-template-areas: 
    "sec1-hre"
    "sec2-hre";
    padding: 15px;
}
.sec1-hre{
    grid-area: sec1-hre;
    border-bottom: 2px solid #a9a9a9;
}
.sec2-hre{
    grid-area: sec2-hre;
    margin-top: 10px;
    overflow-y: auto;
}
.cardHistorial{
    background-color: #f7f7f7;
    height: 50px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    border: 0.5px solid #a9a9a9;
    margin-bottom: 10px;
}
.aviso-hre{
    font-size: 20px;
    color: #7b7b7b;
    font-weight: 600;
    text-align: center;
    margin-top: 30px;
}
.cantidad{
    font-weight: 600;
}

.horaContain{
    background-color: #f7f7f7;
    border: 0.5px solid #a9a9a9;
    width: 70px;
}

.seleccionado{
    border: 3px solid #010a89;
}
.seleccionado:hover{
    border: 3px solid #010a89;
}

@media(max-width:1600px){
    .contenido-re{
        padding: 15px;
        gap: 10px;
        width: 1000px;
        justify-self: center;
        grid-area: contenido-re;
        display: grid;
        grid-template-columns: repeat(2,35%) calc(30% - 15px);
        grid-template-rows: 30px 200px repeat(3,75px);
        grid-template-areas: 
        "s0-re s0-re s0-re"
        "s1-re s2-re notas-re"
        "s3-re s3-re notas-re"
        "s4-re s4-re notas-re"
        "s5-re s5-re notas-re";
        background-color: rgb(255, 255, 255);
        margin-top: 20px;
        box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    
    }
}

@media(max-width:1100px){
    .contenido-re{
        width: 600px;
        height: 850px;
        grid-template-columns: repeat(2,280px);
        grid-template-rows: 300px 30px 200px repeat(3,75px);
        grid-template-areas: 
        "notas-re notas-re"
        "s0-re s0-re"
        "s1-re s2-re"
        "s3-re s3-re"
        "s4-re s4-re"
        "s5-re s5-re";
    }
    
    .historial-re{
        width: 600px;
    }
}

@media(max-width:700px){
    .contenido-re{
        width: 400px;
        height: 850px;
        grid-template-columns: repeat(2,180px);
        grid-template-rows: 350px 30px 150px repeat(3,75px);
        grid-template-areas: 
        "notas-re notas-re"
        "s0-re s0-re"
        "s1-re s2-re"
        "s3-re s3-re"
        "s4-re s4-re"
        "s5-re s5-re";
    }
    .historial-re{
        width: 400px;
        font-size: 12px;
    }
}

@media(max-width:500px){
    .contenido-re{
        width: 300px;
        height: 975px;
        grid-template-columns: repeat(2,130px);
        grid-template-rows: 450px 30px 100px 100px repeat(3,75px);
        grid-template-areas: 
        "notas-re notas-re"
        "s0-re s0-re"
        "s1-re s1-re"
        "s2-re s2-re"
        "s3-re s3-re"
        "s4-re s4-re"
        "s5-re s5-re";
        margin-bottom: 15px;
    }
    .historial-re{
        width: 300px;
    }
    .hora{
        display: none;
    }

} */