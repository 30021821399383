.Anuncio{
    z-index: 101;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
}

.anuncio-content{
    z-index: 101;
    position: fixed;
    top:20%;
    width: 320px;
    height: 500px;
    background-color: aliceblue;
    display: grid;
    grid-template-columns: 300px;
    grid-template-rows: 90px 170px 70px 50px 50px;
    grid-template-areas: 
    "sec0-anu "
    "sec1-anu"
    "sec2-anu"
    "sec3-anu"
    "sec4-anu";
    border-radius: 5px;
    padding: 10px;
    gap: 10px;
    overflow-y: auto;
    animation: desplazar 1s ease-in-out;
}

.sec0-anu{
    display: flex;
    grid-area: sec0-anu;
}
.sec0-anu img{
    width: 100%;
}
.sec1-anu{
    display: flex;
    grid-area: sec1-anu;
    text-align: justify;
    border-bottom: 2px solid #d1d1d1;
    border-top: 2px solid #d1d1d1;
    align-items: center;
}
.sec1-anu span{
    color: rgb(205, 178, 31);
}

.sec2-anu{
    display: flex;
    grid-area: sec2-anu;
    text-align: justify ;
    border-bottom: 2px solid #d1d1d1;
}

.sec3-anu{
    grid-area: sec3-anu;
}

.sec4-anu{
    grid-area: sec4-anu;
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 5px;
}
.sec4-anu button{
    width: 100px;
    height: 40px;
    border-radius: 5px;
    font-size: 20px;
    background-color: white;
    transition: 0.5s;
}
.sec4-anu button:hover{
    transform: scale(1.1);
    color: white;
    background-color: black;
}




.anuncio-content::-webkit-scrollbar {
    width: 0px;
    height: 1px;
}

.anuncio-content::-webkit-scrollbar-track {
    background: transparent;
}

.anuncio-content::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 10px;
}

.anuncio-content::-webkit-scrollbar-thumb:hover {
    background: #555;
}

@keyframes desplazar {
    0% {
      transform: translateY(-100%);
      /* Posición inicial: desplazado hacia arriba */
    }
  
    100% {
      transform: translateY(0);
      /* Posición final: no desplazado */
    }
  }