.DeudaDetails {
    display: flex;
    flex-direction: column;
    gap: 20px;

    header {
        padding: 0 0;
        height: 70px;
    }

    .contenido {
        display: flex;
        flex-direction: column;
        gap: 30px;
        max-width: 1500px;
        height: auto;
        align-self: center;
    }

}

.DeudaDetails .walletOpcion {
    width: 48%;
    height: 180px;
    min-width: 200px;
    border-radius: 10px;
    background-color: #f4f4f4;
    padding: 15px;
    display: flex;
    border: 1px solid #c3c3c3;
    flex-direction: column;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    transition: .4s ease-in-out;

    h3 {
        font-size: 1.2rem;
        line-height: 2rem;
        color: rgb(84, 75, 75);
        text-wrap: wrap;
    }

    span {
        font-size: 2rem;
        line-height: 2rem;
        font-weight: 600;
        letter-spacing: -0.025em;
        line-height: 1;
        white-space: wrap;
    }

    div {
        display: flex;
    }

    img {
        line-height: 2rem;
        width: 30px;
        height: 30px;
        justify-self: center;
        align-self: center;
        margin-inline-end: 10px;
    }

}

.walletOpcion:hover {
    transform: scale(1.02);
    box-shadow: none;
}


.DeudaDetails .historial {
    border-radius: 5px;
    width: 100%;
    max-height: 290px;
    background-color: #ffffff;
    padding: 10px 15px;

    header{
        height: 40px;
    }

    table {
        text-align: center;
        width: 100%;
        border-collapse: collapse;
        table-layout: auto;

        thead {
            border-bottom: 1px solid rgba(0, 0, 0, 0.265);
        }

        thead th {
            color: #697c9b;
        }

        th {
            padding-top: 0.75rem;
            padding-bottom: 0.75rem;
            padding-left: 1rem;
            padding-right: 1rem;
            color: #374151;
            font-weight: 600;
        }

        tbody {
            border-top-width: 1px;
            border-color: #E5E7EB;
        }

        td {
            padding: 1rem 0.75rem;
            color: #374151;
            border-bottom: 1px solid rgba(0, 0, 0, 0.265);
        }

        tr:hover,
        thead:hover {
            background-color: #e4e8f0;
        }
    }

}