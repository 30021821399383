.contain-data-home {
  height: 100%;
  border-radius: 10px;
  background-color: #ffffff;
  padding: 15px;
  display: flex;
  flex-direction: row;
  box-shadow: 0 10px 15px -3px rgba(255, 255, 255, 0.1), 0 4px 6px -2px rgba(255, 255, 255, 0.05);
  transition: .4s ease-in-out;
}

.contain-data-home p {
  font-size: 1rem;
  line-height: 2rem;
  color: rgb(84, 75, 75);
  font-weight: 600;
}
.contain-data-home h3 {
  font-size: 2rem;
  line-height: 2rem;
  font-weight: 600;
  letter-spacing: -0.025em;
  line-height: 1;
  white-space: nowrap;
}
.contain-data-home .case2{
  display: flex;
  flex-direction: row;
}
.contain-data-home:hover {
  transform: scale(1.02);
  border: 3px solid rgb(236, 158, 15);
  box-shadow: none;
}
.contain-data-home img {
  line-height: 2rem;
  width: 30px;
  height: 30px;
  justify-self:center;
  align-self: center;
  margin-inline-end: 10px;
}
.none {
  display: none;
}