@import url("../../../Assets/colors/colors.css");

.userPerfil {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 75px 180px 600px 280px;
    grid-template-areas:
        "sec0-up"
        "sec1-up"
        "sec2-up"
        "sec3-up";
    gap: 15px;
}

.sec0-up {
    grid-area: sec0-up;
}

.sec1-up {
    display: flex;
    grid-area: sec1-up;
    background-color: var(--color-2);
    border-radius: 5px;
    position: relative;
}

.sec2-up {
    grid-area: sec2-up;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 50px repeat(5, 100px);
    background-color: rgb(255, 255, 255);
    grid-template-areas:
        "dtp0 dtp0 dtp0"
        "dtp1 dtp2 dtp2"
        "dtp3 dtp4 dtp4"
        "dtp5 dtp6 dtp6"
        "dtp7 dtp7 dtp7"
        "dtp8 dtp8 dtp8";
    padding: 10px;
    gap: 15px;
    border-radius: 5px;
}

.sec3-up {
    grid-area: sec3-up;
    background-color: white;
    border-radius: 5px;
}

.dtp0 {
    grid-area: dtp0;
    display: flex;
    align-content: center;
    border-bottom: 1px solid #e5e5e5;
}

.dtp1 {
    grid-area: dtp1;
}

.dtp1 input {
    color: #575757;
}

.dtp2 {
    grid-area: dtp2;
}

.dtp2 input {
    color: #575757;
}

.dtp3 {
    grid-area: dtp3;
}

.dtp4 {
    grid-area: dtp4;
}

.dtp5 {
    grid-area: dtp5;
}

.dtp6 {
    grid-area: dtp6;
}

.dtp7 {
    grid-area: dtp7;
}


.sec1-up .userInfo {
    width: 100%;
    height: 55%;
    display: flex;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    position: absolute;
    bottom: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.sec1-up .userInfo .infos {
    width: auto;
    height: auto;
    position: absolute;
    left: 130px;
    top: 10px;
}

.sec1-up .userImg {
    width: 95px;
    height: 95px;
    padding: 5px;
    position: absolute;
    background-color: rgb(255, 255, 255);
    border-radius: 50px;
    z-index: 1;
    top: 35px;
    left: 20px;
}

.sec1-up .uih {
    width: 95px;
    height: 95px;
    position: absolute;
    top: 35px;
    left: 20px;
    background-color: black;
    z-index: 2;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    opacity: 0%;
}

.sec1-up .uih:hover {
    opacity: 30%;
}

.sec1-up button {
    position: absolute;
    top: 100px;
    left: 90px;
    z-index: 2;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border: 1px solid #b0b0b0;
}


.sec3-up {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 50px 75px 75px 75px;
    grid-template-areas:
        "s30-up s30-up"
        "s31-up s31-up"
        "s33-up s33-up"
        "s34-up s34-up";
    padding: 10px;
}

.s30-up {
    display: flex;
    align-content: center;
    border-bottom: 1px solid #e5e5e5;
    grid-area: s30-up;
}

.s31-up {
    grid-area: s31-up;
    background-color: rgba(135, 207, 235, 0.443);
    padding: 5px;
    border-radius: 5px;
    display: block;
}

.s31-up p {
    color: rgb(23, 23, 137);
}

.s32-up {
    grid-area: s32-up;
    display: flex;
    align-items: center;
    justify-content: center;
}

.s32-up p {
    height: 10px;
    color: #575757;
}

.s33-up {
    grid-area: s33-up;
    display: flex;
    align-items: center;
    justify-content: center;
}

.s33-up p {
    height: 10px;
    color: #575757;
}

.s34-up {
    grid-area: s34-up;
}

.sec4-up {
    background-color: white;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 50px 75px 75px 75px;
    padding: 10px;
    border-radius: 5px;
}

.sec4-up .s40-up {
    border-bottom: 1px solid #e5e5e5;
    gap: 10px;
}

.sec4-up .s41-up {
    display: grid;
    justify-content: space-between;
    grid-template-columns: 40% 25% 25%;
    gap: 20px;
}

.sec4-up .s42-up {
    display: grid;
    justify-content: space-between;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.sec4-up .s42-up p {
    font-weight: 600;
}

.sec4-up .s42-up select {
    width: 100%;
    height: 35px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><polygon fill="black" points="0,0 20,0 10,10"/></svg>') no-repeat right 10px center;
    background-color: white;
    background-size: 10px;
    border: 0.5px solid #ccc;
    padding: 5px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
}

.sec4-up .s42-up select option {
    padding: 10px;
    background-color: white;
    color: #333;
    font-size: 16px;
    font-weight: 500;
}

.sec5-up {
    width: 100%;
    display: flex;
}

/* .sec5-up .head {
    border-bottom: 1px solid #e5e5e5;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.sec5-up .faq {
    width: 300px;
    grid-area: s31-up;
    background-color: rgba(135, 207, 235, 0.443);
    padding: 5px;
    border-radius: 5px;
    display: block;
    display: flex;
    justify-content: center;

    p {
        color: rgb(23, 23, 137);
    }
}
.sec5-up .faq:hover {
    cursor: pointer;
}

.sec5-up .botones button{
    margin-inline-end: 10px;
} */

.userPerfil .sec5-up .modal1{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 102;
}

.userPerfil .sec5-up .modal1 .modal1-content{
    background-color: white;
    z-index: 101;
    width: 100%;
    max-width: 700px;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    border: 2px solid rgb(11, 11, 110);
    overflow-y: auto;
    max-height: 600px;
    padding: 15px;
    padding-top: 5px;
}

.userPerfil .sec5-up .modal1 .modal1-content .header{
    padding: 5px;
    display: flex;
    flex-direction: column;
}

.userPerfil .sec5-up .modal1 .modal1-content .header button{
    width: 30px;
    margin-inline-start: auto;
    transition: 0.4s;
    background-color: transparent;
    border-radius: 5px;
    border: none;
    font-size: 25px;
}
.userPerfil .sec5-up .modal1 .modal1-content .header button:hover{
    background-color: #e9e9e9;
}
.userPerfil .sec5-up .modal1 .modal1-content .body {
    display: flex;
    flex-direction: column;
    gap: 7px;
}
.userPerfil .sec5-up .modal1 .modal1-content .body p {
    font-size: 19px;
    font-weight: 600;
    color: rgb(83, 83, 83);
}
.userPerfil .sec5-up .modal1 .modal1-content .body .inputs {
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.userPerfil .sec5-up .modal1 .modal1-content .body .inputs span {
    font-size: 18px;
    color: rgb(83, 83, 83);
}

.userPerfil .sec5-up .modal1 .modal1-content .body .inputs select {
    height: 40px;
    width: 100%;
    font-size: 20px;
    border-radius: 5px;
    color: #616161;
    padding: 0 5px;
}

.userPerfil .sec5-up .modal1 .modal1-content .botones{
    padding: 10px;
    display: flex;
    justify-content: space-around;
    padding-top: 15px;
}

@media(max-width:1100px) {
    .userPerfil {
        grid-template-rows: 75px 180px 600px 350px;
        grid-template-areas:
            "sec0-up"
            "sec1-up"
            "sec2-up"
            "sec3-up";
        padding-bottom: 30px;
    }

    .sec3-up {
        grid-template-columns: 50% 50%;
        grid-template-rows: 50px 75px 75px 75px 75px;
        grid-template-areas:
            "s30-up s30-up"
            "s31-up s31-up"
            "s32-up s32-up"
            "s33-up s33-up"
            "s34-up s34-up";
    }
}

@media(max-width:700px) {
    .userPerfil {
        grid-template-columns: 100%;
        grid-template-rows: 75px 180px 600px 500px;
        grid-template-areas:
            "sec0-up"
            "sec1-up"
            "sec2-up"
            "sec3-up";
    }

    .sec3-up {
        grid-template-columns: 50% 50%;
        grid-template-rows: 50px auto 100px 100px 75px;
        grid-template-areas:
            "s30-up s30-up"
            "s31-up s31-up"
            "s32-up s32-up"
            "s33-up s33-up"
            "s34-up s34-up";
    }

    .s32-up p,
    .s33-up p {
        margin-bottom: 10px;
    }

    .sec4-up .s41-up {
        display: grid;
        justify-content: space-between;
        grid-template-columns: 30% 35% 35%;
        gap: 20px;
        align-items: center;
    }

    .sec4-up {
        background-color: white;
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: 50px 90px 75px 75px;
        padding: 10px;
        border-radius: 5px;
    }
}