.EditarUsuario {
    width: calc(100% - 20px);
    margin-inline: 10px;
    background-color: aliceblue;
    border: 1px solid black;
    display: flex;
    flex-direction: column;
    padding: 10px;
    justify-content: center;
    gap: 5px;
}

.EditarUsuario .enterData {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.EditarUsuario .botones {
    height: 50px;
    display: flex;
    justify-content: space-around;
}

.EditarUsuario .permisos {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.EditarUsuario .permiso {
    display: flex;
    align-items: center;
}

.EditarUsuario span {
    font-size: 20px;
    font-weight: 100;
    margin-inline-start: 10px;
}


.EditarUsuario .enterData div {
    width: 500px;
}

.EditarUsuario .titulo-edtu {
    width: 100%;
    box-sizing: border-box;
    text-align: left;
    font-size: 25px;
    font-weight: 600;
}

.deleteSection {
    display: flex;
    position: absolute;
    justify-content: space-evenly;
    align-items: center;
    padding: 0;
    margin: 0;
    overflow: hidden;
    top: 0;
    left: 0;
}

.overlay-ads {
    background-color: #3333339d;
    position: relative;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
}

.deleteContain {
    position: fixed;
    display: grid;
    background-color: white;
    width: 300px;
    height: 200px;
    grid-template-rows: 150px 50px;
    border-radius: 10px;
}

.sec1-dc {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
}

.sec2-dc {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border-top: 1px solid rgb(133, 133, 133);
}

.sec2-dc button {
    padding: 5px 15px;
    border-radius: 5px;
    border: 1px solid #333;
    color: white;
}

.sec2-dc .cancel {
    background-color: rgb(248, 109, 109);
}

.sec2-dc .ok {
    background-color: rgb(51, 130, 53);
}

.EditarUsuario .creditoEmpresarial {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 10px;
}

.EditarUsuario .detallesAdeudo {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 300px;
    padding: 0;

    button {
        padding: 5px;
    }

    span {
        padding: 0;
        margin: 0;
    }
}

.EditarUsuario .details-deuda {
}

.EditarUsuario .details-deuda-contenido {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.EditarUsuario .details-deuda-contenido header {
    height: 30px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #7a7a7a;
    font-size: 20px;

    button {
        height: 25px;
        width: 25px;
        border: none;
        background-color: transparent;
        font-size: 25px;
    }

    button:hover {
        background-color: #c3c3c3;
        border-radius: 7px;
    }
}

.deuda-detalles{
    display: flex;
    gap: 5px;
    flex-direction: column;
    padding: 15px 0;

    h2{
        font-size: 16px;
    }
    .agregarDeuda{
        display: flex;
        flex-direction: column;
        gap: 5px;
    }
}



@media (max-width:1500px) {
    .EditarUsuario .enterData {
        justify-content: center;
    }
}

@media (max-width:550px) {
    .EditarUsuario .enterData div {
        width: 300px;
    }

}